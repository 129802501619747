import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IvfService } from '../services/ivf.service';
import * as saveAs from 'file-saver';
import { ToastrService } from 'ngx-toastr';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as moment from 'moment';
import { GlobalStorageService } from 'src/app/global-storage.service';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-ivf-booking-confirmation',
  templateUrl: './ivf-booking-confirmation.component.html',
  styleUrls: ['./ivf-booking-confirmation.component.css']
})
export class IvfBookingConfirmationComponent implements OnInit {

  @Input() bookingDetails: any;
  @Input() isDc: boolean = false;
  InvoiceData: any;
  SpouseDTO: any;
  patientDTO: any;
  hospitalAddressInfo: any;
  paymentModes: any;
  constructor(public activeModal: NgbActiveModal,private gloabalService:GlobalStorageService, private ivfService: IvfService, private toastrService: ToastrService) { }

  ngOnInit(): void {
    console.log(this.bookingDetails);
    if(!this.isDc){
      this.pateitnInfo()

    }
    console.log(this.InvoiceData)
  }

  isPrint: boolean = false;
  getInvoiceDetails() {
    this.isPrint = false;
    if (this.isDc) {
      this.getInvoiceDetailsforDc(true)
    } else {
      this.getInvoiceDetailsforDoctor(true)
    }
  }

  printInvoice() {
    this.isPrint = true;
    if (this.isDc) {
      this.getInvoiceDetailsforDc(true)
    } else {
      this.getInvoiceDetailsforDoctor(true)
    }
  }


  invoiceDate: any;
  invoiceData: any;
  invoiceArray = [];
  hospitalInfo: any;
  patientAddress: any;
  hospitalAddress: any;
  patientInfo: any;
  getInvoiceDetailsforDoctor(invoice) {
    this.ivfService.findInvoiceByDoctorAppointmentId(this.bookingDetails?.appointmentId).subscribe(res => {
      this.pdfData = res?.body
      this.pdfData = res?.body;
      this.invoiceData = res?.body?.data;
      this.invoiceArray = this.invoiceData?.patientInvoices;
      this.hospitalInfo = this.invoiceData?.ivfhospitalResponceDTO;
      this.patientAddress = this.invoiceData?.patientAddress;
      this.hospitalAddress = this.invoiceData?.ivfAddress;
      this.patientInfo = res?.body?.data;
      if(invoice){
        this.genPDF()
        this.printPdf(this.isPrint);
      }else{
        this.genOpPdf()
      }
    })
  }

  getInvoiceDetailsforDc(invoice) {
    this.ivfService.findPatientInvoiceForDcAppotment(this.bookingDetails?.appointmentId).subscribe(res => {
      this.pdfData = res?.body
      // this.downloadPdf()
      this.pdfData = res?.body;
      this.invoiceData = res?.body?.data;
      this.invoiceArray = this.invoiceData?.patientInvoices;
      this.hospitalInfo = this.invoiceData?.ivfhospitalResponceDTO;
      this.patientAddress = this.invoiceData?.patientAddress;
      this.hospitalAddress = this.invoiceData?.ivfAddress;
      this.patientInfo = res?.body?.data;
      this.paymentModes=this.patientInfo?.invoicepayment
      if(invoice){
        this.genPDF()
        this.printPdf(this.isPrint);
      }else{
        this.genOpPdf()
      }
    })
  }


  pdfData: any;
  objectUrl: any;
  downloadPdf() {
    this.ivfService.downloadInvoicePDF(this.pdfData).subscribe(res => {
      let entity_name = this.bookingDetails?.appointmentId + '.pdf';
      let blob = new Blob([res], { type: 'application/pdf' });

      saveAs(blob, entity_name);
    },
      error => {
        this.toastrService.error('Error downloading PDF.', error);
      }
    )
  }

  pateitnInfo(){
    this.ivfService.getSpouseDetails(this.bookingDetails?.patientId).subscribe(res => {
      this.InvoiceData = res?.data;
      this.hospitalAddressInfo = this.InvoiceData?.ivfhospitalResponseDTO;
      this.patientDTO = this.InvoiceData?.primaryUserDTO;
      this.SpouseDTO = this.InvoiceData?.spouseUserDTO;
  
  })
}

  printPdf(type) {
    if (type) {
      pdfMake.createPdf(this.printData).print();
    } else {
      pdfMake.createPdf(this.printData).download();
    }
  }

  printOpPdf() {
    
    this.isPrint = true;
    if (this.isDc) {
      this.getInvoiceDetailsforDc(false)
    } else {
      this.getInvoiceDetailsforDoctor(false)
    }
  }

  printOpPdfData: any;
  genOpPdf() {

    const hositalAddress = this.hospitalAddressInfo?.name + '\n' + this.hospitalAddressInfo?.cellNumber + '\n' + this.hospitalAddressInfo?.city + '\n' + this.hospitalAddressInfo?.state + '\n' + this.hospitalAddressInfo.zip;
    const logo = 'data:image/jpeg;base64,' + this.hospitalAddressInfo?.logo;
  
    const invoiceData = this.invoiceArray?.map(function (test, index) {
      const testName = test.testName || "DOCTOR VISIT";
      return [index + 1, testName, test.amount];
    });
  
    const inv_date = moment(this.invoiceData).format('DD-MM-YYYY');
    const dob = this.patientDTO?.dob ? moment().diff(moment(this.patientDTO.dob, 'YYYY-MM-DD'), 'years') : 'N/A';
    const spouseDob = this.SpouseDTO?.dob ? moment().diff(moment(this.SpouseDTO.dob, 'YYYY-MM-DD'), 'years') : 'N/A';
  
    this.printOpPdfData = {
      pageMargins: [20, 130, 20, 50], // [left, top, right, bottom]
      header: function (currentPage, pageCount) {
        return {
          columns: [
            {
              image: logo,
              width: 130,
              height: 120,
              alignment: 'right'
            },
            {
              text: hositalAddress,
              alignment: 'right',
              width: '*',
            },
          ],
          margin: [20, 10, 20, 100],
        };
      },
      content: [
        {
          text: '_'.repeat(100),
          margin: [0, 0, 0, 5],
        },
        {
          columns: [
            { text: ' Name :' + this.patientDTO?.firstName + ' ' + this.patientDTO?.lastName, bold: true },
            { text: ' Patient ID :' + this.patientDTO?.userId, bold: true },
            { text: ' Age :' + dob, bold: true },
          ],
          margin: [0, 10, 0, 0],
        },
        {
          columns: [
            { text: 'Spouse Name :' + this.SpouseDTO?.firstName + ' ' + this.SpouseDTO?.lastName, bold: true },
            { text: ' Spouse ID :' + this.SpouseDTO?.userId, bold: true },
            { text: ' Age: ' + spouseDob, bold: true },
          ],
          margin: [0, 10, 0, 0],
        },
        {
          text: '_'.repeat(100),
          margin: [0, 0, 0, 5],
        },
      ],
      styles: {
        tableExample: {
          margin: [0, 10, 0, 50],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
        },
      },
      footer: function(currentPage, pageCount) {
        return {
          absolutePosition: { x: 40, y: -40 }, 
          
            canvas: [
              { type: 'line', x1: 0, y1: 0, x2: 595 - 0, y2: 0, lineWidth: 1 } // Bottom border line
            ],
        
          text: [
            { text: '© 2024, Powered By CureMetric Health Tech.', alignment: 'center' },
          ]
        };
      },
      
    };
  
    pdfMake.createPdf(this.printOpPdfData).print();
  }
  
  printData: any;
  printDataWithoutHeader: any;
  genPDF() {

    const hositalAddress = this.hospitalInfo?.cellNumber + '\n' + this.hospitalInfo?.city + '\n' + this.hospitalInfo?.state + '\n' + this.hospitalInfo.zip;
    const logo = 'data:image/jpeg;base64,' + this.hospitalInfo?.logo;

    const invoiceData = this.invoiceArray?.map(function (test, index) {
      const testName = test.testName || "DOCTOR VISIT";
      return [index + 1, testName, test.amount];
    });

    const inv_date = moment(this.invoiceData).format('DD-MM-YYYY')

    invoiceData.unshift([
      { text: 'SL.No', style: 'tableHeader' },
      { text: 'Service Name', style: 'tableHeader' },
      { text: 'Amount', style: 'tableHeader' }
    ]);
    const paymentModes = this.patientInfo?.invoicepayment || [];

    const paymentModeRows = paymentModes.map(payment => {
      return [
        { text: payment.mode, alignment: 'left' },
        { text: payment.amount, alignment: 'right' }
      ];
    });


    this.printData = {
      pageMargins: [20, 130, 20, 50], // [left, top, right, bottom]
      header: function (currentPage, pageCount) {
        return {
          columns: [
            {
              image: logo,
              width: 130,
              height: 120,
              alignment: 'right'
            },
            {
              text: hositalAddress,
              alignment: 'right',
              width: '*',
            },
          ],
          margin: [20, 10, 20, 100],
        };
      },
      content: [
        {
          columns: [
            { text: 'Patient Name :' + this.invoiceData?.patientname, bold: true },
            { text: 'Invoice Date :' + inv_date, alignment: 'right', bold: true },
          ],
          margin: [0, 10, 0, 0],
        },
        {
          columns: [
            { text: 'Patient Id :' + this.invoiceData?.userId, bold: true },
            { text: 'Invoice Id :' + this.invoiceData?.ivfinvoiceId, alignment: 'right', bold: true },
          ],
          margin: [0, 10, 0, 0],
        },
        {
          style: 'tableExample',
          table: {
            widths: [60, '*', 'auto'],
            body: invoiceData,
          },
          layout: 'lightHorizontalLines',
        },
        {
          columns: [
            { text: 'Total Amount', bold: true, alignment: 'right' },
            { text: this.invoiceData?.advancePay, alignment: 'right', width: 100 },
          ],
          margin: [0, 10, 0, 0],
        },
        {
          columns: [
            { text: 'Discount(%)', bold: true, alignment: 'right' },
            { text: this.invoiceData?.discount, alignment: 'right', width: 100 },
          ],
          margin: [0, 10, 0, 0],
        },
        {
          columns: [
            { text: 'Due Amount', bold: true, alignment: 'right' },
            { text: this.invoiceData?.duePay, alignment: 'right', width: 100 },
          ],
          margin: [0, 10, 0, 0],
        },
        {
          columns: [
            { text: 'Net Amount', bold: true, alignment: 'right' },
            { text: this.invoiceData?.advancePay, alignment: 'right', width: 100 },
          ],
          margin: [0, 10, 0, 0],
        },

        ...(paymentModes.length > 0 ? [
          {
            text: 'Mode of Payment',
            bold: true,
            margin: [0, 10, 0, 5]
          },
          {
            table: {
              widths: ['*', 100],
              body: [
                ['Mode', 'Amount'],
                ...paymentModeRows
              ]
            },
            layout: 'lightHorizontalLines'
          }
        ] : []),
      ],
      styles: {
        tableExample: {
          margin: [0, 10, 0, 50],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
        },
      },
      footer: function(currentPage, pageCount) {
        return {
          absolutePosition: { x: 40, y: -40 }, 
          
            canvas: [
              { type: 'line', x1: 0, y1: 0, x2: 595 - 0, y2: 0, lineWidth: 1 } // Bottom border line
            ],
        
          text: [
            { text: '© 2024, Powered By CureMetric Health Tech.', alignment: 'center' },
          ]
        };
      },
    };

    this.printDataWithoutHeader = {
      pageMargins: [20, 130, 20, 10], // [left, top, right, bottom]
      content: [
        {
          columns: [
            { text: 'Patient Name :' + this.invoiceData?.patientname, bold: true },
            { text: 'Invoice Date :' + inv_date, alignment: 'right', bold: true },
          ],
          margin: [0, 10, 0, 0],
        },
        {
          columns: [
            { text: 'Patient Id :' + this.invoiceData?.userId, bold: true },
            { text: 'Invoice Id :' + this.invoiceData?.ivfinvoiceId, alignment: 'right', bold: true },
          ],
          margin: [0, 10, 0, 0],
        },
        {
          style: 'tableExample',
          table: {
            widths: [60, '*', 'auto'],
            body: invoiceData,
          },
          layout: 'lightHorizontalLines',
        },
        {
          columns: [
            { text: 'Total Amount', bold: true, alignment: 'right' },
            { text: this.invoiceData?.advancePay, alignment: 'right', width: 100 },
          ],
          margin: [0, 10, 0, 0],
        },
        {
          columns: [
            { text: 'Discount(%)', bold: true, alignment: 'right' },
            { text: this.invoiceData?.discount, alignment: 'right', width: 100 },
          ],
          margin: [0, 10, 0, 0],
        },
        {
          columns: [
            { text: 'Due Amount', bold: true, alignment: 'right' },
            { text: this.invoiceData?.duePay, alignment: 'right', width: 100 },
          ],
          margin: [0, 10, 0, 0],
        },
        {
          columns: [
            { text: 'Net Amount', bold: true, alignment: 'right' },
            { text: this.invoiceData?.advancePay, alignment: 'right', width: 100 },
          ],
          margin: [0, 10, 0, 0],
        },
      ],
      styles: {
        tableExample: {
          margin: [0, 10, 0, 50],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
        },
      },
    };

  }


}
