import { GlobalStorageService } from 'src/app/global-storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { ApicallsService } from '../../../../../src/app/apicalls.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class IvfService {
  [x: string]: any;

  constructor(private globalStorage: GlobalStorageService, private httpClient: HttpClient, private apiService: ApicallsService) { 
    const hostname = window.location.hostname;

    if (hostname === 'localhost') {
      this.baseUrl = environment.ivfUrl1;
      this.ivfUrl = environment.ivfUrl1;
      this.doctorUrl = environment.doctorBaseUrl1;
      this.elasticUrl = environment.elesticSearchUrl1;
      this.utilitiesUrl = environment.utilitiesUrl1;
      this.pdfUrl = environment.pdfUrl1;
      this.patientUrl= environment.patientBaseUrl1
    }
  }

  baseUrl = environment.ivfUrl
  ivfUrl = environment.ivfUrl
  doctorUrl = environment.doctorBaseUrl
  elasticUrl = environment.elesticSearchUrl
  utilitiesUrl = environment.utilitiesUrl
  pdfUrl = environment.pdfUrl
  patientUrl = environment.patientBaseUrl

  ivfPharmacyAddressId = new BehaviorSubject<number>(0);
  ivfPharmacyIdSubject$ = this.ivfPharmacyAddressId.asObservable();
  dataFromIvf = new BehaviorSubject<any>({})
  dataFromIvf$ = this.dataFromIvf.asObservable();
  dataFromScheduler = new BehaviorSubject<boolean>(false);
  dataFromScheduler$ = this.dataFromIvf.asObservable();

  private refreshDashboardSubject = new Subject<void>();
  refreshDashboard$ = this.refreshDashboardSubject.asObservable();


  private patientRegisteredSubject = new Subject<void>();
  patientRegistered$ = this.patientRegisteredSubject.asObservable();

  triggerRefresh() {
    this.refreshDashboardSubject.next();
  }


  triggerPatientRegistered() {
    this.patientRegisteredSubject.next();
  }


  private sortOrder: string = 'asc';
  private sortField: string = '';

  sort(array: any[], field: string): any[] {
    if (this.sortField === field) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortOrder = 'asc';
    }

    this.sortField = field;
    const comparison = this.sortOrder === 'asc' ? 1 : -1;

    return array.sort((a, b) => {
      if (typeof a[field] === 'string' && typeof b[field] === 'string') {
        return a[field].localeCompare(b[field]) * comparison;
      } else if (typeof a[field] === 'number' && typeof b[field] === 'number') {
        return (a[field] - b[field]) * comparison;
      } else {
        return 0;
      }
    });
  }
  encryptObjectToBase64(obj: any): string {
    const jsonString = JSON.stringify(obj);
    return btoa(jsonString);
  }


  postWithOutSessions(url, body) {
    return this.apiService.post(this.baseUrl + url, body,{})
  }
  postWithSession(url, body) {
    return this.apiService.post(this.baseUrl + url, body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }

  myAppointments(url, body) {
    return this.apiService.post(this.baseUrl + url, body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' },true)
  }

  viewNotification(url, body) {
    return this.apiService.post(this.baseUrl + url, body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' },true)
  }
  registerNewPatient(url, body){
    const userObject = {
      "moduleName": this.globalStorage.getItem('loggedInAs'),
      "refernceId": this.globalStorage.getItem('entityId'),
      "userId": this.globalStorage.getItem('userId'),
    }
    console.log(userObject)
    const newOptions = {
      headers: new HttpHeaders(
        {
          'user-session': JSON.parse(this.globalStorage.getItem('userSession')),
          "user":this.encryptObjectToBase64(userObject),
          // "userId":this.globalStorage.getItem('userId'),
          // "forUserId": this.globalStorage.getItem('forUserId'),
          // "moduleName": this.globalStorage.getItem('loggedInAs'),
          'Cache-Control': 'no-store, no-cache',
          //'Content-Type': 'application/json'
        })
    }
    return this.apiService.post(this.baseUrl + url, body,newOptions)
  }
  appointmentBook(url, body, id) {
    return this.apiService.post(this.baseUrl + url, body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': id.toString() }), observe: 'response' })
  }
  postwithparamsMultipartWithSession(url, formData) {
    return this.apiService.post(this.baseUrl + url, formData, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }
  postWithSessionWithEmptyBody(url) {
    return this.apiService.post(this.baseUrl + url, {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' },true)
  }
  getIvfCount(url) {
    return this.apiService.post(this.baseUrl + url, {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' },true)
  }
  getWithSessionAndAddresId(url) {
    return this.apiService.get(this.baseUrl + url, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }
  getNotifications(body) {
    return this.apiService.post(this.baseUrl +'notificationalerts/get/all',body , { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' },true)
  }
  doctorTable(body){
    return this.apiService.post(this.baseUrl +'fetchIvfUserByType',body,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' },true)

  }
  putWithSession(url, body) {
    return this.apiService.put(this.baseUrl + url, body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }
  getDoctorSlots(ivfAddressId, id) {
    const fromDate = new Date().toISOString().split('T')[0];
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() + 14);
    const toDateStr = toDate.toISOString().split('T')[0];
    var obj = {
      "associateEntityId": ivfAddressId,
      "doctorId": id,
      "maxResutls": 100,
      "pageNum": 1,
      "fromDate": fromDate,
      "toDate": toDateStr,
    }
    return this.apiService.post(this.doctorUrl + 'schedule/all/bycriteria', obj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  deleteWithSession(url) {
    return this.apiService.delete(this.baseUrl + url, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }
  searchWithElasticSearch(body) {
    return this.apiService.post(this.elasticUrl + 'searchPatient', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) },true)
  }
  searchNewWithElasticSearch(body) {
    return this.apiService.post(this.elasticUrl + 'searchIvfPatient', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) },true)
  }
  searchTemplate(body) {
    return this.apiService.post(this.ivfUrl + 'searchTemplateByDescription', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) },true)
  }
  typeSearchWithElasticSearch(type, value) {
    return this.apiService.get(this.elasticUrl + 'referralTypeController/searchReferralType?vlaue=' + value + '&ivfAddressId=' + this.globalStorage.getItem('ivfAddressId') + '&type=' + type, {});
  }
  languages() {
    return this.apiService.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=LANGUAGE&type=LANGUAGE',{});
  }
  searchIvfAppointments(body) {
    return this.apiService.post(this.baseUrl + 'searchMyAppointments', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) },true)
  }
  getNationalty() {
    return this.apiService.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=COUNTRY&type=COUNTRY',{});
  }
  getSurgeryAndAilments(service) {
    return this.apiService.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=' + service + '&type=' + service, {})
  }

  getIvfSpecs() {
    return this.apiService.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=IVFSPECIALIZATION&type=IVFSPECIALIZATION', {})
  }

  getMedicalHistoryIVFData() {
    return this.apiService.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=IVFINVESTIGATION&type=IVFINVESTIGATION', {})
  }

  getIvfStaticInvestigations() {
    return this.apiService.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=IVFTEST&type=IVFTEST', {})

  }


  getLookupData(key) {
    return this.apiService.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module='+key+'&type='+key, {},true)
  }

  getIvfMedicines(url){
    return this.apiService.get(this.baseUrl+url,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  // fetchIvfMedicalTest?gender="+patientgender+"&ivfAddressId="+ivfaddressID.toString()+"&testType="+testtypeselection
  getIvfInvestigationsByType(patientgender, ivfaddressID, testtypeselection) {
    return this.apiService.get(this.baseUrl + 'fetchIvfMedicalTest?gender=' + patientgender + '&ivfAddressId=' + ivfaddressID + '&testType=' + testtypeselection, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }

  getIVFPrimaryDetails(userId) {
    return this.apiService.get(this.baseUrl + 'getPrimaryDetails?ivfuserId=' + userId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }

  searchPatient(body) {
    body[  "maxResult"]= 20;
    body["pageNumber"]= 1;
    return this.apiService.post(this.baseUrl + 'searchUser', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) },true)
  }

  updateIVFPrimaryDetails(body) {
    return this.apiService.put(this.baseUrl + 'updateprimaryDetails', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }

  findIVfPayments(addressId,maxResult,pageNumber) {
    return this.apiService.get(this.baseUrl + 'findInvoiceByIvfAddressId?ivfAddressId=' + addressId+'&maxResult='+maxResult+'&pageNumber='+pageNumber, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }

  findIvfInvoiceById(invoiceId) {
    return this.apiService.get(this.baseUrl + 'findInvoiceByInvoiceId?invoiceId=' + invoiceId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }

  findPatientInvoiceByInvoiceId(invoiceId) {
    return this.apiService.get(this.baseUrl + 'findPatientInvoiceByInvoiceId?invoiceId=' + invoiceId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }
  ivftInvoiceTemplate(invoiceId) {
    return this.apiService.get(this.baseUrl + 'ivftInvoiceTemplate?invoiceId=' + invoiceId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }

  findInvoiceByDoctorAppointmentId(appointmentId) {
    const ivfAddressId=this.globalStorage.getItem('ivfAddressId');
    return this.apiService.get(this.baseUrl + 'findInvoiceByDoctorAppointmentId?appointmentId=' + appointmentId +'&ivfaddressId=' + ivfAddressId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }


  findPatientInvoiceForDcAppotment(appointmentId){
    const ivfAddressId=this.globalStorage.getItem('ivfAddressId');
    return this.apiService.get(this.baseUrl + 'findPatientInvoiceForDcAppointment?appointmentId=' + appointmentId +'&ivfaddressId=' + ivfAddressId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }

  getIvfaddress(ivfaddressId) {
    return this.apiService.get(this.baseUrl + 'getIvfaddress?ivfaddressId=' + ivfaddressId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }

  getIVFUserDetails(ivfAddressId, userId) {
    return this.apiService.get(this.baseUrl + 'getUserDetail?ivfAddressId=' + ivfAddressId + '&userId=' + userId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }

  getIVFPersonalDetails(userId) {
    return this.apiService.get(this.baseUrl + 'getPersonaldetail?userId=' + userId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }

  getConsolidatedCaseDetails(ivfAddressId,userId) {
    return this.apiService.get(this.baseUrl + 'consolidatedCaseDetailView?ivfAddressId=' + ivfAddressId +'&patientId='+userId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }

  downloadCaseDetailsPDF(body) : Observable<any>{
    return this.httpClient.post(this.pdfUrl + 'caseDetials', body,{responseType: 'blob' as 'json'});
  }

  downloadInvoicePDF(body) : Observable<any>{
    return this.httpClient.post(this.pdfUrl + 'ivf-invoice', body,{responseType: 'blob' as 'json'});
  }

  downloadReportPDF(url,body) : Observable<any>{
    return this.httpClient.post(this.pdfUrl + url, body,{responseType: 'blob' as 'json'});
  }

  saveIvfCaseDetails(body) {
    return this.apiService.post(this.ivfUrl + 'saveCaseDetailsInformation', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  saveProtocolpackage(body) {
    return this.apiService.post(this.baseUrl + 'saveProtocolpackage', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }
  
  addUserDetail(body) {
    return this.apiService.post(this.baseUrl + 'addUserDetail', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  saveEquipment(body) {
    return this.apiService.post(this.baseUrl + 'saveEquipment', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  saveEquipmentQC(body) {
    return this.apiService.post(this.baseUrl + 'saveEquipmentQC', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  saveEquipmentQCAttribute(body) {
    return this.apiService.post(this.baseUrl + 'saveEquipmentQCAttribute', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  equipmentQCForMonth(body) {
    return this.apiService.post(this.baseUrl + 'equipmentQCForMonth', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }



  saveDisposable(body) {
    return this.apiService.post(this.baseUrl + 'saveDisposable', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  disposableList(body) {
    return this.apiService.post(this.baseUrl + 'disposableList', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }


  saveProtocolDef(body) {
    return this.apiService.post(this.baseUrl + 'saveProtocolDef', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  getIvfPatientVitals(addressId,userId){
    if(userId){
      return this.apiService.get(this.baseUrl+'getUserVitals?ivfAddressId='+addressId+'&userId='+userId,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
    }
  }

  getDonors(){
    return this.apiService.get(this.baseUrl+'donorDetails/'+this.globalStorage.getItem('ivfAddressId'),{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe:'response' })
  }
  

  getIvfPatients(){
    return this.apiService.get(this.baseUrl+'getUserDetailByIvfAddressId?ivfAddressId=' + this.globalStorage.getItem('ivfAddressId') + '&maxResult=' + 100 + '&pageNummber=' + 1,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe:'response'},true)
  }
  followupAppointmentList(){
    return this.apiService.get(this.baseUrl+'followupAppointmentList?ivfAddressId=' + this.globalStorage.getItem('ivfAddressId')+'&maxResult=10&pageNumber=1',{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe:'response'},true)
  }
  
  checkedInAppointmentList(){
    return this.apiService.get(this.baseUrl+'checkedInAppointmentList?ivfAddressId=' + this.globalStorage.getItem('ivfAddressId'),{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe:'response'},true)
  }
  

  findDonorInformations(patientId){
    return this.apiService.get(this.baseUrl+'findDonorInformations?ivfAddressId='+this.globalStorage.getItem('ivfAddressId')+'&patientId='+patientId,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe:'response' })
  }
  packageAmountForCaseCycle(patientId){
    return this.apiService.get(this.baseUrl+'packageAmountForCaseCycle?ivfAddressId='+this.globalStorage.getItem('ivfAddressId')+'&patientId='+patientId,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe:'response' })
  }



  disposableItemtype(){
    return this.apiService.get(this.baseUrl+'disposableItemList?ivfAddressId='+this.globalStorage.getItem('ivfAddressId'),{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe:'response' })
  }


  fetchProtocolPackage(ivfAddressId){
    return this.apiService.get(this.baseUrl + 'fetchProtocolpackage?ivfAddressId=' + ivfAddressId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  fetchProtocolPackageInformation(ivfAddressId,protocolDefId){
    return this.apiService.get(this.baseUrl + 'fetchProtocolPackageInformation?ivfAddressId=' + ivfAddressId+'&protocolDefId='+protocolDefId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  savePackages(body) {
    return this.apiService.post(this.baseUrl + 'savePackages', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  fetchProtocolDefInformation(ivfAddressId){
    return this.apiService.get(this.baseUrl + 'fetchProtocolDefInformation?ivfAddressId=' + ivfAddressId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) },true)
  }

  fetchEquipmentList(ivfAddressId){
    return this.apiService.get(this.baseUrl + 'equipmentList/' + ivfAddressId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) },true)
  }

  fetchAttributesList(equipmentId){
    return this.apiService.get(this.baseUrl + 'equipmentQCAttributeList/'+equipmentId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) },true)
  }

  fetchPackagesByIvfAddressId(ivfAddressId){
    return this.apiService.get(this.baseUrl + 'fetchPackagesByIvfAddressId?ivfAddressId=' + ivfAddressId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) },true)
  }

  fetchPackagesByIvfAddressIdbyCriteria(body){
    return this.apiService.post(this.baseUrl + 'fetchPackagesByIvfAddressId', body,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) },true)
  }

  fetchIvfMedicalTestAllInformation(){
    return this.apiService.get(this.baseUrl + 'fetchIvfMedicalTestAllInformation?ivfAddressId=' + this.globalStorage.getItem('ivfAddressId'), { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) },true)
  }
  checkMedicalLicense(ivfAddressId,licenceNumber){
    return this.apiService.get(this.baseUrl + 'iseLicenceNumberRegisteredOrNot?ivfAddressId=' + ivfAddressId+'&licenceNumber='+licenceNumber, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  existingEmail(ivfAddressId,email){
    return this.apiService.get(this.baseUrl + 'isEmailIdRegisteredOrNot?ivfAddressId=' + ivfAddressId+'&emailId='+email, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  userPreVitalsHistory(body){
    return this.apiService.post(this.baseUrl + 'userVitals', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  checkPhoneNumber(ivfAddressId,mobile){
    return this.apiService.get(this.baseUrl + 'iscellNumberRegisteredOrNot/' + mobile+'/'+ivfAddressId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  equipmentQCForDay(body) {
    return this.apiService.post(this.baseUrl + 'equipmentQCForDay', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  updateIvfMedicalTests(body) {
    return this.apiService.post(this.baseUrl + 'updateIvfMedicalTests', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }


  saveOocyteScan(body) {
    return this.apiService.post(this.baseUrl + 'saveOocyteScan', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }



  fetchIvfMedicalTests(body) {
    return this.apiService.post(this.baseUrl + 'fetchIvfMedicalTests', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  oocyteScanDetail(body) {
    return this.apiService.post(this.baseUrl + 'oocyteScanDetail', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }


  fetchPregnancyscan(medicalTestId) {
    return this.apiService.get(this.ivfUrl + 'fetchPregnancyscan?ivfAddressId=' + this.globalStorage.getItem('ivfAddressId') + '&medicalTestId=' + medicalTestId , { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  findOocyteScanByAppointmentId(appointmentId,medicalTestId) {
    return this.apiService.get(this.ivfUrl + 'findOocyteScanByAppointmentId?ivfAddressId=' + this.globalStorage.getItem('ivfAddressId') + '&medicalTestId=' + medicalTestId + '&patientAppointmentId=' + appointmentId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }
  getOocyteScanForPatient(patientId,appointmentId) {
    return this.apiService.get(this.ivfUrl + 'getOocyteScanForPatient?ivfaddressId=' + this.globalStorage.getItem('ivfAddressId') + '&patient=' + patientId + '&patientAppointmentId=' + appointmentId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }
  getIvfCaseDetails(ivfAddressId, userId) {
    return this.apiService.get(this.ivfUrl + 'retriveCaseDetailsInformation?ivfAddressId=' + ivfAddressId + '&patientId=' + userId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }
  postIvfClinicalExaminationDetails(body) {
    return this.apiService.post(this.ivfUrl + 'saveClinicalExamination', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  checkIn(body){
    return this.apiService.post(this.ivfUrl + 'checkForAppointment',body,{})
  }

  getIvfClinicalExaminationDetails(ivfAddressId, caseDetailInformationId) {
    return this.apiService.get(this.ivfUrl + 'retriveClinicalExamination?ivfAddressId=' + ivfAddressId + '&caseDetailInformationId=' + caseDetailInformationId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  postIvfInvestigations(body) {
    return this.apiService.post(this.baseUrl + 'insertInvestigations', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }
  savePregnancyScan(body) {
    return this.apiService.post(this.baseUrl + 'savePregnancyScan', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }
  
  saveEquipmentMaintenance(body) {
    return this.apiService.post(this.baseUrl + 'saveEquipmentMaintenance', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  equipmentMaintenanceList(equipmentId) {
    return this.apiService.get(this.baseUrl + 'equipmentMaintenanceList?equipmentId='+equipmentId+'&ivfAddressId='+this.globalStorage.getItem('ivfAddressId'), { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  getIvfInvestigations(ivfAddressId, patientId) {
    return this.apiService.get(this.baseUrl + 'investigationsInformation?ivfAddressId=' + ivfAddressId + '&patientId=' + patientId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  ivfPrescriptionTemplateDetails(ivfAddressId, ivfPrescriptionTemplateId) {
    return this.apiService.get(this.baseUrl + 'ivfPrescriptionTemplateDetails?addressId=' + ivfAddressId + '&ivfPrescriptionTemplateId=' + ivfPrescriptionTemplateId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }
  ivfPrescriptionTemplateList() {
    return this.apiService.get(this.baseUrl + 'ivfPrescriptionTemplateList?addressId=' + this.globalStorage.getItem('ivfAddressId') , { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) },true)
  }
  
  investigationsDetails(body) {
    return this.apiService.post(this.baseUrl + 'investigationsDetails',body ,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }
  

  uploadDocument(url, body) {
    return this.apiService.post(this.baseUrl + url, body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  checkMobileNumberExists(cellNumber) {
    return this.apiService.post(this.baseUrl + 'verifyPhoneNumberExists?cellNumber=' + cellNumber, null, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }
  saveDepartment(body) {
    return this.apiService.post(this.baseUrl + 'saveDepartment',body ,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }
  departmentInformation() {
    return this.apiService.get(this.baseUrl + 'departmentInformation/' +this.globalStorage.getItem('ivfAddressId'), { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) },true)
  }

  checkIdExists(type,id) {
    return this.apiService.get(this.baseUrl + 'isAadhaarCardRegisteredOrNot/' + type+'/'+id, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }
  getSpouseDetails(id) {
    return this.apiService.get(this.baseUrl + 'userDetail?ivfAddressId='+this.globalStorage.getItem('ivfAddressId')+'&userId='+id,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }


  checkEmailExists(email) {
    return this.apiService.post(this.baseUrl + 'isEmailRegistered?email=' + email, null, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })

  }
  fetchCycle(ivfAddressId, patientId){
    return this.apiService.get(this.baseUrl + 'fetchCycle?ivfAddressId=' + ivfAddressId + '&patientId=' + patientId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }
  startIvfCycle(body) {
    return this.apiService.post(this.baseUrl + 'startCaseCycle', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  startStimulation(body) {
    return this.apiService.post(this.baseUrl + 'startStimulation', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  startProcedures(body) {
    return this.apiService.post(this.baseUrl + 'startProcedures', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  triggerHcg(body) {
    return this.apiService.post(this.baseUrl + 'triggerHcg', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  updateIvfCycle(body) {
    return this.apiService.put(this.baseUrl + 'UpdateCaseCycle', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  getPincodeDetails(postal, cc){
    return this.apiService.get(this.utilitiesUrl+'countrycodecontroller/getCountrycode?countrycode='+cc+'&postalcode='+postal,{ })
  }


  savePrescriptions(body, userId) {
    return this.apiService.post(this.baseUrl + 'saveInPatientPrescription', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), })
  }
  savePrescriptionInOocyteScan(body, userId) {
    return this.apiService.post(this.baseUrl + 'patientPrescriptionInOocyteScan', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), })
  }

  saveDonorDetails(body){
    return this.apiService.post(this.baseUrl +'saveDonorInformation', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'forUserId': this.globalStorage.getItem('forUserId'), 'loggedInAs': this.globalStorage.getItem('loggedInAs') }), })
  }

  searchDonorInformation(body){
    return this.apiService.post(this.baseUrl +'searchDonorInformation', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'forUserId': this.globalStorage.getItem('forUserId'), 'loggedInAs': this.globalStorage.getItem('loggedInAs') }), })

  }

  getIVFReports(request){
    return this.apiService.post(this.baseUrl+'reports/excuteDashboard',request,{})
  }

  fetchDonorDetails(){
    return this.apiService.get(this.baseUrl + 'donorInformationList?ivfAddressId=' + this.globalStorage.getItem('ivfAddressId'), { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'forUserId': this.globalStorage.getItem('forUserId'), 'loggedInAs': this.globalStorage.getItem('loggedInAs') }), },true)
  }
  allEmbryoGamete(){
    return this.apiService.get(this.baseUrl + 'allEmbryoGamete?ivfAddressId=' + this.globalStorage.getItem('ivfAddressId'), { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'forUserId': this.globalStorage.getItem('forUserId'), 'loggedInAs': this.globalStorage.getItem('loggedInAs') }), },true)
  }
  allSemenGamete(){
    return this.apiService.get(this.baseUrl + 'allSemenGametes?ivfAddressId=' + this.globalStorage.getItem('ivfAddressId'), { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'forUserId': this.globalStorage.getItem('forUserId'), 'loggedInAs': this.globalStorage.getItem('loggedInAs') }), },true)

  }


  fetchDonorDetailsById(donorId){
    return this.apiService.get(this.baseUrl + 'donorInformationDetails?ivfAddressId=' + this.globalStorage.getItem('ivfAddressId') + '&donorInformationId=' + donorId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'forUserId': this.globalStorage.getItem('forUserId'), 'loggedInAs': this.globalStorage.getItem('loggedInAs') }), })
  }

  getIvfPrescriptions(ivfAddressId, value,pgNum,maxResults) {
    return this.apiService.get(this.baseUrl + 'fetchIvfPatientPrescription?ivfAddressId=' + ivfAddressId + '&value=' + value +'&pageNumber='+pgNum+'&maxResult='+maxResults, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) },true)
  }

  fetchScheduleStimulationInformation(body) {
    return this.apiService.post(this.baseUrl + 'fetchScheduleStimulationInformation', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), },true)
  }

  fetchScheduleTriggerInformation(body) {
    return this.apiService.post(this.baseUrl + 'fetchScheduleTriggerInformation', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), },true)
  }
  fetchIvfPatientPrescriptionForInHouseTest(body) {
    return this.apiService.post(this.baseUrl + 'fetchIvfPatientPrescriptionForInHouseTest', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), },true)
  }

  fetchAllWorkSheet(body){
    return this.apiService.post(this.baseUrl + 'fetchAllWorkSheet', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), },true)

  }

  getAllPrescriptionsBy(inHouseTest, patientId, prescriptionId) {
    return this.apiService.get(this.baseUrl + 'medicaltest/view/allTotalAmount?inHouseTest='+ inHouseTest+'&ivfAddressId=' + this.globalStorage.getItem('ivfAddressId') + '&patientId=' + patientId + '&prescriptionId=' + prescriptionId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs'), }) },true)
  }
  getAllTechnicians(ivfAddressId) {
    return this.apiService.get(this.baseUrl + 'fetchTechnicianInformation?ivfAddressId=' + ivfAddressId + '&type=TECHNICIAN', { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  getTechnicianSlots(ivfAddressId, ivfUserId) {
    return this.apiService.get(this.baseUrl + 'fetchSlotForIvfUser?ivfAddressId=' + ivfAddressId + '&ivfUserId=' + ivfUserId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  addPrescriptionSlot(body) {
    return this.apiService.post(this.baseUrl + 'addMedicalTestAppointment', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  fetchIvfSentinalDates(casecycleId,ivfaddressId, patientId) {
    if(casecycleId &&ivfaddressId && patientId){
    return this.apiService.get(this.baseUrl + 'fetchIvfSentinalDates?casecycleId='+casecycleId+ '&ivfAddressId=' + ivfaddressId + '&patientId=' + patientId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
    }
  }

  fetchAllGameteDetail(cycle,ivfAddressId, patientId) {
    if(cycle && patientId){
    return this.apiService.get(this.baseUrl + 'fetchAllGameteDetail?cycle='+cycle+ '&ivfAddressId=' + ivfAddressId + '&patientId=' + patientId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
    }
  }

  startTreatment(body){
    return this.apiService.post(this.baseUrl +'startCycleAndTreatment', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'forUserId': this.globalStorage.getItem('forUserId'), 'loggedInAs': this.globalStorage.getItem('loggedInAs') }), })
  }
  endCycle(body){
    return this.apiService.post(this.baseUrl +'endCycle', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'forUserId': this.globalStorage.getItem('forUserId'), 'loggedInAs': this.globalStorage.getItem('loggedInAs') }), })
  }

  fetchTreatmentDetails(casecycleId,ivfAddressId, patientId) {
    if(casecycleId && patientId){
    return this.apiService.get(this.baseUrl + 'treatmentDetails?casecycleId='+casecycleId+ '&ivfAddressId=' + ivfAddressId + '&patientId=' + patientId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
    }
  }
  treatmentPlanForPatientList(casecycleId,ivfAddressId, patientId) {
    if(casecycleId && patientId){
      const body={
        "casecycleId": casecycleId,
        "ivfAddressId": ivfAddressId,
        "patientId": patientId
      }
    return this.apiService.post(this.baseUrl + 'treatmentPlanForPatientList',body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
    }
  }

  


  insertDNAfragmentation(body) {
    return this.apiService.post(this.baseUrl + 'insertDnaFragmentationInIvf', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  fetchAllCycles(patId){
    return this.apiService.get(this.baseUrl + 'fetchAllCycles?ivfAddressId='+this.globalStorage.getItem('ivfAddressId')+'&patientId='+patId,{});
  }
  fetchRiskFactorsList(patId){
    return this.apiService.get(this.baseUrl + 'fetchRiskFactorsList?ivfAddressId='+this.globalStorage.getItem('ivfAddressId')+'&patientId='+patId,{},true);
  }
  getDoctorInformation(id,type){
    return this.apiService.get(this.baseUrl + 'getDoctorInformation?doctorId=' + id + '&ivfaddressId=' +this.globalStorage.getItem('ivfAddressId') + '&moduleType=' + type,{},true);
  }



  saverisks(body){
    return this.apiService.post(this.baseUrl + 'saveRiskFactors',body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })

  }


  // dashboardsAvailable(){
  //   return this.apiService.get(this.baseUrl + 'reports/dashboardsAvailable',{});
  // }

  // dashboardParameters(id){
  //   return this.apiService.get(this.baseUrl + 'reports/dashboardParameters?dashboardId='+id,{});
  // }

  // exeDashboard(body){
  //   return this.apiService.post(this.baseUrl +'reports/excuteDashboard',body,{});
  // }

  fetchDNAfragmentDetails(ivfaddressId, patientId) {
    return this.apiService.get(this.baseUrl + 'fetchDnaFragmentationDetails?ivfaddressId=' + ivfaddressId + '&patientId=' + patientId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  fetchSpermDFIintegrityTestInfo(ivfAddressId, patientId, testId) {
    return this.apiService.get(this.baseUrl + 'fetchSpermDfiIntegrityTestInformation?ivfAddressId=' + ivfAddressId + '&patientId=' + patientId + '&spermDfiIntegrityTestId=' + testId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) });
  }

  saveIvfData(value) {
    this.dataFromIvf.next(value)
    // this.dataStringSource.next(this.dataFromIvf);
  }

  prescriptionSlotBooked(val) {
    this.dataFromScheduler.next(val)
  }

  followUp(body){
    return this.apiService.post(this.baseUrl + 'saveFollowupAppointment', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }
  saveOocytePickup(body) {
    return this.apiService.post(this.baseUrl + 'saveOocytePickup', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  saveCollactionReport(body) {
    return this.apiService.post(this.baseUrl + 'saveCollactionReport', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }
  saveThawDetailReport(body){
    return this.apiService.post(this.baseUrl + 'saveThawDetailReport', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  fetchOocytePickup(ivfAddressId, patientId, oocytePickupId) {
    return this.apiService.get(this.baseUrl + 'fetchOocytePickup?ivfAddressId=' + ivfAddressId + '&patientId=' + patientId + '&oocytePickupId=' + oocytePickupId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  oocytePickupDetails(ivfAddressId, patientId) {
    return this.apiService.get(this.baseUrl + 'oocytePickupDetails?ivfAddressId=' + ivfAddressId + '&patientId=' + patientId , { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  saveSemenAnalysisInformation(body) {
    return this.apiService.post(this.baseUrl + 'saveUsersemenanalysisInformation', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }
  saveSpermMotilityInformation(body) {
    return this.apiService.post(this.baseUrl + 'saveSpermMotilityInformation', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }
  saveMorphologyAssessmentInformation(body) {
    return this.apiService.post(this.baseUrl + 'saveMorphologyAssessmentInformation', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }
  savePhysicalAssessmentInformation(body) {
    return this.apiService.post(this.baseUrl + 'savePhysicalAssessmentInformation', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }
  saveadvanceSpermInformation(body) {
    return this.apiService.post(this.baseUrl + 'saveadvanceSpermInformation', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  getIvfSurgeries() {
    return this.apiService.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=IVF&type=IVF', {})
  }

  getIvfProcedures() {
    return this.apiService.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=IVFPROCEDURE&type=IVFPROCEDURE', {})
  }

  getIvfTreatmentplan(){
    return this.apiService.get(this.baseUrl+'templateList?ivfAddressId='+this.globalStorage.getItem('ivfAddressId'),{})
  }
  getIvfTreatmentplanV2(body){
    return this.apiService.post(this.baseUrl+'templateList',body,{})
  }
  getDoctorAppointmentTypes(){
    return this.apiService.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=DoctorAppointment&type=DoctorAppointment', {})
  }

  getDcAppointmentTypes(){
    return this.apiService.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=DcAppointment&type=DcAppointment', {},true)
  }

  uploadPicSemenAnalysisReport(ivfaddressId, patientId, usersemenanalysisId, body) {
    return this.apiService.post(this.baseUrl + 'uploadPicSemenAnalysisReport?ivfAddressId=' + ivfaddressId + '&patientId=' + patientId + '&userSemenAnalysisId=' + usersemenanalysisId, body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  uploadImageInIvfWorksheet(dayNo,embryoId,ivfWorkSheetId,ivfAddressId,patientId,body){
    return this.apiService.post(this.baseUrl + 'uploadImageInIvfWorksheet?dayNo=' + dayNo + '&embryoId=' + embryoId + '&ivfWorkSheetId=' + ivfWorkSheetId + '&ivfAddressId=' + ivfAddressId + '&patientId=' + patientId, body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  uploadImageInEmbryoThawworksheet(dayNo,embryoId,embryoThawWorkSheetId,ivfAddressId,patientId,body){
    return this.apiService.post(this.baseUrl + 'uploadImageInEmbryoThawworksheet?dayNo=' + dayNo +'&embryoId=' +embryoId+'&embryoThawWorkSheetId=' + embryoThawWorkSheetId + '&ivfAddressId=' + ivfAddressId + '&patientId=' + patientId, body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  showImageInIvfWorksheet(body){
    return this.apiService.post(this.baseUrl + 'showImageInIvfWorksheet',body,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }


  showImageInEmbryoThawworksheet(body){
    return this.apiService.post(this.baseUrl + 'showImageInEmbryoThawworksheet',body,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  uploadProfilePic(userId, body) {
    return this.apiService.post(this.baseUrl + 'profilepic/' + userId, body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  fetchProfilePic(userId) {
    return this.apiService.get(this.baseUrl + 'fetchProfilePic?userId=' + userId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  saveIvfPatientPrescription(body){
    return this.apiService.post(this.baseUrl+'saveInPatientPrescription',body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  addPatientPrescription(body){
    return this.apiService.post(this.baseUrl+'addPatientPrescription',body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  savePatientInvestiagtions(body){
    return this.apiService.post(this.baseUrl+'saveInvestigations',body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response','Cache-Control': 'no-store, no-cache' })

  }

  getPicSemenAnalysisReport(ivfAddressId, patientId, usersemenanalysisId) {
    return this.apiService.get(this.baseUrl + 'showPicSemenAnalysisReport?ivfAddressId=' + ivfAddressId + '&patientId=' + patientId + '&userSemenAnalysisId=' + usersemenanalysisId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  getIdDocument(ivfAddressId, userId) {
    return this.apiService.get(this.baseUrl + 'getpersionalDetailsPic?ivfaddressId=' + ivfAddressId + '&userId=' + userId , { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  saveIvfWorkSheet(body) {
    return this.apiService.post(this.baseUrl + 'saveIvfWorkSheet', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  fetchIvfWorkSheet(body) {
    return this.apiService.post(this.baseUrl + 'fetchIvfWorkSheet', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  saveEmbryo(body) {
    return this.apiService.post(this.baseUrl + 'saveEmbryo', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  addBodyFrame(body) {
    return this.apiService.post(this.baseUrl + 'addBodyFrame', body, {})
  }

  bodyFrameDetail(ivfAddressId, userId) {
    return this.apiService.get(this.baseUrl + 'bodyFrameDetail?ivfAddressId=' + ivfAddressId + '&patientId=' + userId , { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  saveEmbryoDayOne(body) {
    return this.apiService.post(this.baseUrl + 'saveEmbryoDayOne', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }
  updateDonorDetail(body) {
    return this.apiService.post(this.baseUrl + 'updateDonorDetail', body, {})
  }

  updateCycleDonor(body) {
    return this.apiService.post(this.baseUrl + 'updateCycleDonor', body, {})
  }

  saveEmbryoDayTwo(body) {
    return this.apiService.post(this.baseUrl + 'saveEmbryoDayTwo', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  saveEmbryoDayThree(body) {
    return this.apiService.post(this.baseUrl + 'saveEmbryoDayThree', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  saveEmbryoDayFour(body) {
    return this.apiService.post(this.baseUrl + 'saveEmbryoDayFour', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  saveEmbryoDayFive(body) {
    return this.apiService.post(this.baseUrl + 'saveEmbryoDayFive', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  saveEmbryoDaySix(body) {
    return this.apiService.post(this.baseUrl + 'saveEmbryoDaySix', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  saveEmbryofreezing(body) {
    return this.apiService.post(this.baseUrl + 'saveEmbryofreezing', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  saveEmbryotransferworksheet(body){
    return this.apiService.post(this.baseUrl + 'saveEmbryotransferworksheet', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })

  }

  saveDayOneEmbryoThawWorkSheet(body){
    return this.apiService.post(this.baseUrl + 'saveDayOneEmbryoThawWorkSheet', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  saveDayTwoEmbryoThawWorkSheet(body){
    return this.apiService.post(this.baseUrl + 'saveDayTwoEmbryoThawWorkSheet', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  saveDayThreeEmbryoThawWorkSheet(body){
    return this.apiService.post(this.baseUrl + 'saveDayThreeEmbryoThawWorkSheet', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  saveDayzFourEmbryoThawWorkSheet(body){
    return this.apiService.post(this.baseUrl + 'saveDayzFourEmbryoThawWorkSheet', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  saveDayzFiveEmbryoThawWorkSheet(body){
    return this.apiService.post(this.baseUrl + 'saveDayzFiveEmbryoThawWorkSheet', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  saveThawingSurvivality(body){
    return this.apiService.post(this.baseUrl + 'saveThawingSurvivality', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  embryoTransferWorksheetDetails(body){
    return this.apiService.post(this.baseUrl + 'embryoTransferWorksheetDetails', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })

  }




  embryoTransfer(body) {
    return this.apiService.post(this.baseUrl + 'embryoTransfer', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  embryofreezingDetails(ivfAddressId, worksheetId) {
    return this.apiService.get(this.baseUrl + 'embryofreezingDetails/' + ivfAddressId +'/'+ worksheetId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  getUserSemenAnalysisReports(ivfAddressId, patientId) {
    return this.apiService.get(this.baseUrl + 'retrieveUserSemenanaLysis?ivfaddressId=' + ivfAddressId + '&patientId=' + patientId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  getUserSemenAnalysisInformation(ivfAddressId, patientId, userSemenAnalysisId) {
    return this.apiService.get(this.baseUrl + 'fetchUserSemenAnalysisInformation?ivfAddressId=' + ivfAddressId + '&patientId=' + patientId + '&userSemenAnalysisId=' + userSemenAnalysisId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  getphysicalAssessment(ivfAddressId, patientId, userSemenAnalysisId) {
    return this.apiService.get(this.baseUrl + 'fetchPhysicalAssessmentInformation?ivfAddressId=' + ivfAddressId + '&patientId=' + patientId + '&userSemenAnalysisId=' + userSemenAnalysisId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  getSpermMotility(ivfAddressId, patientId, userSemenAnalysisId) {
    return this.apiService.get(this.baseUrl + 'fetchSpermMotilityInformation?ivfAddressId=' + ivfAddressId + '&patientId=' + patientId + '&userSemenAnalysisId=' + userSemenAnalysisId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  getASFPA(ivfAddressId, patientId, userSemenAnalysisId) {
    return this.apiService.get(this.baseUrl + 'fetchAdvanceSperm?ivfAddressId=' + ivfAddressId + '&patientId=' + patientId + '&userSemenAnalysisId=' + userSemenAnalysisId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }
  getMorphologyAssessment(ivfAddressId, patientId, userSemenAnalysisId) {
    return this.apiService.get(this.baseUrl + 'fetchMorphologyAssessment?ivfAddressId=' + ivfAddressId + '&patientId=' + patientId + '&userSemenAnalysisId=' + userSemenAnalysisId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  saveSemenReport(body) {
    return this.apiService.post(this.baseUrl + 'saveSemenReportRequest', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  getSemenReport(ivfAddressId, Id) {
    return this.apiService.get(this.baseUrl + 'fetchSemenReport?ivfAddressId=' + ivfAddressId + '&spermPreparationId=' + Id, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }


  saveSemenCulture(body) {
    return this.apiService.post(this.baseUrl + 'saveSemenCultureSensitivity', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  getSemenCulture(ivfAddressId, patientId) {
    return this.apiService.get(this.baseUrl + 'fetchSemenCultureSensitivityDetails?ivfaddressId=' + ivfAddressId + '&patientId=' + patientId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  getSemenCultureById(semenculturesensitivityId) {
    return this.apiService.get(this.baseUrl + 'semenCultureSensitivityInformation?semenculturesensitivityId=' + semenculturesensitivityId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  saveMesaGeneric(body) {
    return this.apiService.post(this.baseUrl + 'registerAndrologyTestReport', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  saveEmbryoCryopreservation(body) {
    return this.apiService.post(this.baseUrl + 'saveEmbryoCryopreservation', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }
  fetchEmbryoCryopreservationDetails(ivfAddressId,embryoCryopreservationId,patientId) {
    return this.apiService.get(this.baseUrl + 'fetchEmbryoCryopreservationDetails?embryoCryopreservationId='+embryoCryopreservationId+'&ivfAddressId='+ivfAddressId+'&patientId='+patientId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  fetchEmbryogameteDetails(body) {
    return this.apiService.post(this.baseUrl + 'fetchEmbryogameteDetails', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }
  semenFreezingReportDetails(ivfAddressId,patientId) {
    return this.apiService.get(this.baseUrl + 'semenFreezingReportDetails?ivfAddressId='+ivfAddressId+'&patientId='+patientId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }


  embryoThawWorksheetDetails(body){
    return this.apiService.post(this.baseUrl + 'embryoThawWorksheetDetails', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })

  }

  saveEmbryoThawWorksheet(body) {
    return this.apiService.post(this.baseUrl + 'saveEmbryoThawWorksheet', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }
  saveEmbryoThawDetails(body) {
    return this.apiService.post(this.baseUrl + 'saveEmbryoThawDetails', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  saveOocyteFreezingReport(body) {
    return this.apiService.post(this.baseUrl + 'saveOocyteFreezingReport', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  fetchOocyteFreezingReportDetails(ivfAddressId,oocyteFreezingReportId,patientId) {
    return this.apiService.get(this.baseUrl + 'fetchOocyteFreezingReportDetails?ivfAddressId='+ivfAddressId+'&oocyteFreezingReportId='+oocyteFreezingReportId+'&patientId='+patientId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  fetchUserInformation(appointmentId,ivfAddressId) {
    return this.apiService.get(this.baseUrl + 'fetchUserInformation?appointmentId='+appointmentId+'&ivfAddressId='+ivfAddressId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  fetchMesaGeneric(ivfAddressId,andrologyTestReportId) {
    return this.apiService.get(this.baseUrl + 'fetchAndrologyTest?ivfAddressId=' + ivfAddressId + '&andrologyTestReportId=' + andrologyTestReportId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  saveSpermFreezingReport(body) {
    return this.apiService.post(this.baseUrl + 'saveSemenFreezingReport', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  fetchSpermFreezingReport(ivfAddressId, andrologyTestReportId) {
    return this.apiService.get(this.baseUrl + 'fetchSemenFreezingReport?ivfAddressId=' + ivfAddressId + '&semenFreezingReportId=' + andrologyTestReportId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  equipmentInformation(body){
    body['ivfAddressId']=this.globalStorage.getItem('ivfAddressId')
    return this.apiService.post(this.baseUrl + 'equipmentInformation', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  setToken(body){
    body['ivfAddressId']=this.globalStorage.getItem('ivfAddressId')
    return this.apiService.post(this.baseUrl + 'fcm/token', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), },true)
  }


  fetchIVFroles() {
    return this.apiService.post(this.baseUrl + 'allRole', {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), });
  }
  saveIUIDReport(body) {
    return this.apiService.post(this.baseUrl + 'saveIuIdDetails', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), });
  }
  fetchIUIDdetails(id, ivfAddressId) {
    return this.apiService.get(this.baseUrl + 'fetchIuIdInformation?iuId=' + id + '&ivfAddressId=' + ivfAddressId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) });
  }
  donorDetail(id) {
    return this.apiService.get(this.baseUrl + 'donorDetail?ivfAddressId='+this.globalStorage.getItem('ivfAddressId')+'&userId='+id, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) });
  }
  getFemaleDonors(status) {
    return this.apiService.get(this.baseUrl + 'donorDetail?ivfAddressId='+this.globalStorage.getItem('ivfAddressId')+'&status='+status, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') },) },true);
  }

  saveIUIHinfo(body) {
    return this.apiService.post(this.baseUrl + 'saveIuihInformation', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), });
  }
  populateSemenAnalysisView(ivfaddressId, analysisId) {
    return this.apiService.get(this.baseUrl + "retriveUserSemenAnalysisInformation?ivfAddressId=" + ivfaddressId + "&userSemenAnalysisId=" + analysisId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  populateSpermDFIView(ivfAddressId, patientId, referenceId) {
    return this.apiService.get(this.baseUrl + "fetchSpermDfiIntegrityTestInformation?ivfaddressId=" + ivfAddressId + "&patientId=" + patientId + "&spermDfiIntegrityTestId=" + referenceId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }


  getIUIH_Data(id, ivfAddressId) {
    return this.apiService.get(this.baseUrl + 'fetchIuihInformation?iuihId=' + id + '&ivfAddressId=' + ivfAddressId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }
  getGynecologist(ivfAddressId) { return this.apiService.get(this.baseUrl + "fetchTechnicianInformation?ivfAddressId=" + ivfAddressId + "&type=Gynecologist", { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) }) }

  getEmbryologist(ivfAddressId) { return this.apiService.get(this.baseUrl + "fetchTechnicianInformation?ivfAddressId=" + ivfAddressId + "&type=Embryologist", { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) }) }

  getAnaesthesist(ivfAddressId) { return this.apiService.get(this.baseUrl + "fetchTechnicianInformation?ivfAddressId=" + ivfAddressId + "&type=Anaesthetist", { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) }) }

  //?caseId=5&ivfAddressId=188&patientId=23
  fetchAllAndrologyDetails(caseId, ivfAddressId, patientId) {
    return this.apiService.get(this.baseUrl + 'fetchAllAndrologyDetails?caseId=' + caseId + '&ivfAddressId=' + ivfAddressId + '&patientId=' + patientId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) });

  }

  fetchAllEmbryologyDetails(caseId, ivfAddressId, patientId) {
    return this.apiService.get(this.baseUrl + 'fetchAllEmbryoDetails?caseId=' + caseId + '&ivfAddressId=' + ivfAddressId + '&patientId=' + patientId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) });

  }

  getprogressiveNotes(caseDetailsInformationId) {
    return this.apiService.get(this.baseUrl + 'retriveProgressnoteInIvf?caseDetailsInformationId=' + caseDetailsInformationId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }
  saveProgressiveNotes(body) {
    return this.apiService.post(this.baseUrl + 'saveProgressnote', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  saveSupplier(body) {
    return this.apiService.post(this.baseUrl + 'saveSupplier', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  getSuppier(ivfAddressId) {
    return this.apiService.get(this.baseUrl + "getSupplier?ivfaddressId=" + ivfAddressId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }
  suplier(hospitalAddressId) {
    return this.apiService.get(this.baseUrl + 'getSupplier?ivfaddressId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  inventoryorders(hospitalAddressId) {
    return this.apiService.get(this.baseUrl + 'getSupplierorders?ivfaddressId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  category(hospitalAddressId) {
    return this.apiService.get(this.baseUrl + 'getCatagory?ivfaddressId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  Inventory(hospitalAddressId) {
    return this.apiService.get(this.baseUrl + 'getInventory?ivfaddressId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  subcategory(categoryId, hospitalAddressId) {
    return this.apiService.get(this.baseUrl + 'getItemtype?catagoryId=' + categoryId + '&ivfaddressId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  SupplierInformation(supplierId) {
    return this.apiService.get(this.baseUrl + 'getSupplierInformation?supplierId=' + supplierId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  supplierOrderDeliver(hospitalAddressId, supplierordersId) {
    return this.apiService.get(this.baseUrl + 'supplierOrderDeliver?ivfaddressId=' + hospitalAddressId + '&supplierordersId=' + supplierordersId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getInventoryByInventoryId(hospitalAddressId, inventoryId) {
    return this.apiService.get(this.baseUrl + 'getInventoryByInventoryId?ivfaddressId=' + hospitalAddressId + '&inventoryId=' + inventoryId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  updateinventory(body) {
    return this.apiService.put(this.baseUrl + 'updateInventory', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getSupplierordersBySupplierordersId(hospitalAddressId, supplierordersId) {
    return this.apiService.get(this.baseUrl + 'getSupplierordersBySupplierordersIdAndivfaddressId?ivfaddressId=' + hospitalAddressId + '&supplierordersId=' + supplierordersId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  cancelSupplierorders(body) {
    return this.apiService.post(this.baseUrl + 'cancleSupplierorders', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  // https://dev.cure-metric.com/api/hospital/cancleSupplierorders
  editSubCategory(hospitalAddressId, itemTypeId) {
    return this.apiService.get(this.baseUrl + 'getItemtypeByhospitalAddresIdanditemTypeId?ivfaddressId=' + hospitalAddressId + '&itemTypeId=' + itemTypeId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  updateSubcategory(body) {
    return this.apiService.post(this.baseUrl + 'saveItemtype', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  editCatagory(catagoryId, hospitalAddressId) {
    return this.apiService.get(this.baseUrl + 'getCatagoryByivfaddressIdAndcatagoryId?catagoryId=' + catagoryId + '&ivfaddressId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }



  getAssignSupplierDetails(hospitalAddressId) {
    return this.apiService.get(this.baseUrl + 'getSuppliercatgery?ivfaddressId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  saveIvfPayment(body) {
    return this.apiService.post(this.baseUrl + 'payment/addPayment', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }


  editAssignSupplier(body) {
    return this.apiService.put(this.baseUrl + 'editSuppliercatgeryrelation', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  activateCatagory(catagoryId, hospitalAddressId) {
    return this.apiService.post(this.baseUrl + 'activateCatagory?catagoryId=' + catagoryId + '&ivfaddressId=' + hospitalAddressId, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  deleteCatagory(catagoryId, hospitalAddressId) {
    return this.apiService.delete(this.baseUrl + 'deleteCatagory?catagoryId=' + catagoryId + '&ivfaddressId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  activateSubCatagory(hospitalAddressId, itemTypeId) {
    return this.apiService.post(this.baseUrl + 'activateItemtype?addressId=' + hospitalAddressId + '&itemTypeId=' + itemTypeId, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  deleteSubCatagory(hospitalAddressId, itemTypeId) {
    return this.apiService.delete(this.baseUrl + 'deleteItemtype?addressId=' + hospitalAddressId + '&itemTypeId=' + itemTypeId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  ActiveAssignSupplier(hospitalAddressId, suppliercatgeryId) {
    return this.apiService.post(this.baseUrl + 'activateSuppliercatgery?ivfaddressId=' + hospitalAddressId + '&suppliercatgeryId=' + suppliercatgeryId, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  deleteAssignSupplier(hospitalAddressId, suppliercatgeryId) {
    return this.apiService.delete(this.baseUrl + 'deleteSuppliercatgery?ivfaddressId=' + hospitalAddressId + '&suppliercatgeryId=' + suppliercatgeryId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  ActiveSupplierCategory(hospitalAddressId, supplierId) {
    return this.apiService.post(this.baseUrl + 'activateSupplier?ivfaddressId=' + hospitalAddressId + '&supplierId=' + supplierId, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  getSuppliercatgeryByItemTypeId(hospitalAddressId, itemTypeId) {
    return this.apiService.get(this.baseUrl + 'getSuppliercatgeryByItemTypeId?ivfaddressId=' + hospitalAddressId + '&itemTypeId=' + itemTypeId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  saveCategory(body) {
    return this.apiService.post(this.baseUrl + 'saveCatagory', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  saveInventory(body) {
    return this.apiService.post(this.baseUrl + 'saveInventory', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  getSupplierInformation(supplierId) {
    return this.apiService.get(this.baseUrl + 'getSupplierInformation?supplierId=' + supplierId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  deactivateSupplier(supplierId) {
    return this.apiService.delete(this.baseUrl + 'deleteSupplier?supplierId=' + supplierId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  saveinventory(body) {
    return this.apiService.post(this.baseUrl + 'saveInventory', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  savesupplierOrders(body) {
    return this.apiService.post(this.baseUrl + 'saveSupplierorders', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })

  }

  saveSubCategory(body) {
    return this.apiService.post(this.baseUrl + 'saveItemtype', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  savesuppliercategory(body) {
    return this.apiService.post(this.baseUrl + 'saveSuppliercatgery', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  getReportNotes(id, testType) {
    return this.apiService.get(this.baseUrl + 'fetchTestNote?ivfAddressId=' + this.globalStorage.getItem('ivfAddressId') + '&referenceId=' + id + '&type=' + testType, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  saveReportNotes(body) {
    return this.apiService.post(this.baseUrl + 'saveTestNote', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  saveArtReportData(body) {
    return this.apiService.post(this.baseUrl + 'saveArtReport', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), });
  }

  getArtReport(casecycleId,ivfAddressId,patientId){
    const body={
      casecycleId:casecycleId,
      ivfAddressId:ivfAddressId,
      patientId:patientId
    }
    return this.apiService.post(this.baseUrl + 'dischargeSummeryDetails',body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) });

  }

  saveEmbryoDonorTransferData(body) {
    return this.apiService.post(this.baseUrl + 'saveEmbryoDonorXfr', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), });
  }

  fetchEmbryoDonorXfrDetails(embryoDonorId) {
    return this.apiService.get(this.baseUrl + 'fetchEmbryoDonorXfrDetails?embryoDonorXfrId=' + embryoDonorId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) });
  }

  saveVitals(request: any, userId: any){
    return this.apiService.post(this.baseUrl + 'saveVitals',
      request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        , 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId?.toString()
      }), observe: 'response'
    })
  }
  saveTreatmentTemplate(body){
    return this.apiService.post(this.baseUrl + 'addTemplate', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }
  saveTreatmentPlan(body){ 
    return this.apiService.post(this.baseUrl + 'saveTemplatePrescription', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }
  getTreatmentPlan(addressId){
    return this.apiService.get(this.baseUrl + 'findTemplatePrescription?addressId='+addressId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) },true)
  }
  getTreamentByTreatmentId(body){
    return this.apiService.post(this.baseUrl + 'templatePlan',body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }
  getMedicinesAndTestList(day,gender,addressId,templatePrescriptionId){
    return this.apiService.get(this.baseUrl+'findTemplatePlan?day='+day+'&gender='+gender+'&ivfAddressId='+addressId+'&templatePrescriptionId='+templatePrescriptionId,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }
  checkPackage(body){
    return this.apiService.post(this.baseUrl + 'checkForPackage', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) },true)

  }

  updateGet(ivfuserId){
    return this.apiService.post(this.baseUrl + 'fetchIvfUserDetails?ivfAddressId='+this.globalStorage.getItem('ivfAddressId')+'&ivfuserId='+ivfuserId,{}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) },true)

  }

  updateStaff(body){
    return this.apiService.put(this.baseUrl + 'updateprimaryDetails',body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })

  }
  donorAssign(body){
    return this.apiService.post(this.baseUrl + 'donorAssigneToPatient',body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })

  }
  saveCorrectionInvoice(body){
    return this.apiService.post(this.baseUrl + 'saveInvoiceCorrection',body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })

  }
  sendOtp(id){
    return this.apiService.post(this.baseUrl + 'password/forgot?emailId='+id +'&resetPwdLink=ssss',{}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }
  resetPassword(body){
    return this.apiService.post(this.baseUrl + 'create/password', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })
  }

  getCycles(body){
    return this.apiService.post(this.baseUrl + 'casecycleInformation', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId'), "moduleName": this.globalStorage.getItem('loggedInAs') }) })

  }
}
