import { GlobalStorageService } from 'src/app/global-storage.service';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApicallsService } from 'src/app/apicalls.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  pharmaUrl = environment.pharmaUrl

  clinicUrl = environment.hospitalUrl

  ivfUrl = environment.ivfUrl
  dcUrl = environment.doctorBaseUrl
  baseUrl: string = '';
  moduleName
  constructor(private globalStorage: GlobalStorageService, private api: ApicallsService) {
    const hostname = window.location.hostname;

    if (hostname === 'localhost') {
      this.pharmaUrl = environment.pharmaUrl1
      this.clinicUrl = environment.hospitalUrl1
      this.ivfUrl = environment.ivfUrl1
      this.dcUrl = environment.diagnosticBaseUrl1
    }
    console.log('module',this.globalStorage.getItem('loggedInAs'))
    switch (this.globalStorage.getItem('loggedInAs')) {
      case 'HOSPITAL':
        this.baseUrl = this.clinicUrl;
        break;
      case 'IVF':
        this.baseUrl = this.ivfUrl;
        break;
      case 'PHARMACY':
        this.baseUrl = this.pharmaUrl;
        break;
        case 'DIAGNOSTIC_CENTER':
        this.baseUrl = this.dcUrl;
        break;
    }
  }
  getBaseUrl(module){
    console.log('module',module)
    switch (module) {
      case 'HOSPITAL':
        this.baseUrl = this.clinicUrl;
        break;
      case 'IVF':
        this.baseUrl = this.ivfUrl;
        break;
      case 'PHARMACY':
        this.baseUrl = this.pharmaUrl;
        break;
        case 'DIAGNOSTIC_CENTER':
        this.baseUrl = this.dcUrl;
        break;
    }

  }
  getAddressId(){
    this.moduleName = this.globalStorage.getItem('loggedInAs')
    switch (this.moduleName) {
      case 'HOSPITAL':
        this.baseUrl = this.clinicUrl;
        return this.globalStorage.getItem('hospitalAddressId')
        break;
      case 'IVF':
        this.baseUrl = this.ivfUrl;
        return this.globalStorage.getItem('ivfAddressId')
        break;
      case 'PHARMACY':
        this.baseUrl = this.pharmaUrl;
        return this.globalStorage.getItem('pharmacyId')
        break;
    }
  }

  getInventory() {
    return this.api.get(this.baseUrl + 'inventoryList/' + this.getAddressId(), {},true);
  }
  getOrders() {
  }
  deliverOrder(body) {
    return this.api.post(this.baseUrl + 'supplierOrderDeliver', body, {})
  }
  getBatchInformation(body) {
    return this.api.post(this.baseUrl + 'batchInfomation', body, {});
  }

  saveCategory(body) {
    body['addressId']=this.getAddressId();
    return this.api.post(this.baseUrl + 'saveCatagory', body, {})
  }

  savesuppliercategory(body) {
    return this.api.post(this.baseUrl + 'saveSuppliercatgery', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  inventory(status) {
    return this.api.get(this.baseUrl + 'inventoryList/' + this.getAddressId(), {},true);
  }

  inventoryorders(body) {
    return this.api.post(this.baseUrl + 'supplierordersList', body, {},true);
  }

  getSuppliercatgeryByItemTypeId(body) {
    return this.api.post(this.baseUrl + 'suppliercatgeryByItemTypeIdList', body, {})
  }
  saveSubCategory(body) {
    body['addressId']=this.getAddressId();
    return this.api.post(this.baseUrl + 'saveItemtype', body, {})
  }
  orderPayment(body) {
    body['addressId']=this.getAddressId();
    return this.api.post(this.baseUrl + 'orderPayment', body, {})
  }

  updateSubCategory(body) {
    return this.api.post(this.baseUrl + 'updateItemtype', body, {})
  }

  saveSupplierdetails(body) {
    body['addressId']=this.getAddressId();
    return this.api.post(this.baseUrl + 'saveSupplier', body, {})
  }

  saveInventory(body) {
    body['addressId']=this.getAddressId();
    return this.api.post(this.baseUrl + 'saveInventoryItem', body, {})
  }
  SupplierInformation(supplierId) {
    return this.api.get(this.baseUrl + 'getSupplierInformation?supplierId=' + supplierId, {})
  }
  suplier(body) {
    body["addressId"] = this.getAddressId();
    return this.api.post(this.baseUrl + 'supplierList', body, {},true)
  }
  searchItemTypeForOrder(body) {
    body["addressId"] = this.getAddressId();
    return this.api.post(this.baseUrl + 'searchItemTypeForOrder', body, {},true)
  }

  supplierOrderItemList(body) {
    body["addressId"] = this.getAddressId();
    return this.api.post(this.baseUrl + 'supplierOrderItem', body, {},true)
  }

  category(body) {
    body["addressId"] = this.getAddressId();
    return this.api.post(this.baseUrl + 'categoryList', body, {},true)
  }

  assignSupplierForItem(body) {
    body["addressId"] = this.getAddressId();
    return this.api.post(this.baseUrl + 'assignSupplierForItem', body, {})
  }

  getAllCatagories() {
    const body=
      {
        "flag": "ALL",
        "addressId": this.getAddressId()
    }
    return this.api.post(this.baseUrl + 'categoryList', body, {},true)
  }

  activateCatagory(catagoryId, hospitalAddressId) {
    return this.api.post(this.baseUrl + 'activateCatagory?catagoryId=' + hospitalAddressId + '&addressId=' + catagoryId, {}, {})
  }

  deleteCatagory(catagoryId, hospitalAddressId) {
    return this.api.delete(this.baseUrl + 'deleteCatagory?catagoryId=' + catagoryId + '&addressId=' + hospitalAddressId, {})
  }
  subcategory(body) {
    return this.api.post(this.baseUrl + 'itemtypeList', body, {});
  }
  ActiveAssignSupplier(hospitalAddressId, suppliercatgeryId) {
    return this.api.post(this.baseUrl + 'activateSuppliercatgery?addressId=' + this.getAddressId() + '&suppliercatgeryId=' + suppliercatgeryId, {}, {})
  }
  deleteAssignSupplier(hospitalAddressId, suppliercatgeryId) {
    return this.api.delete(this.baseUrl + 'deleteSuppliercatgery?addressId=' + this.getAddressId() + '&suppliercatgeryId=' + suppliercatgeryId, {})
  }
  savesupplierOrders(body) {
    return this.api.post(this.baseUrl + 'saveSupplierorders', body, {})
  }

  getordersByorderId(addressId, supplierordersId) {
    return this.api.get(this.baseUrl + 'getSupplierordersBySupplierordersIdAndAddresId?addressId=' + this.getAddressId() + '&supplierordersId=' + supplierordersId, {});
  }

  SuppliercatgeryByItemType(hospitalAddressId, itemTypeId) {
    return this.api.get(this.baseUrl + 'getSuppliercatgeryByItemType?addressId=' + hospitalAddressId + '&itemTypeId=' + itemTypeId, {})
  }
  cancelSupplierorders(body) {
    return this.api.post(this.baseUrl + 'cancleSupplierorders', body, {})
  }
  ActiveSupplierCategory(supplierId) {
    return this.api.post(this.baseUrl + 'activateSupplier?addressId=' + this.getAddressId() + '&supplierId=' + supplierId, {}, {})
  }
  deactivateSupplier(supplierId) {
    return this.api.delete(this.baseUrl + 'deleteSupplier?supplierId=' + supplierId, {})
  }

  updateSupplierorders(body) {
    return this.api.post(this.baseUrl + 'editSupplierorders', body, {});
  }

  allCategoryList() {
    return this.api.get(this.baseUrl + 'allCatagoryActiveList?addressId=' + this.getAddressId(), {})
  }

  allsubCategoryList(catId) {
    return this.api.get(this.baseUrl + 'allActiveItemtype?addressId=' + this.getAddressId() + '&catagoryId=' + catId, {})
  }

  getSuppliercatgeryBySupplierId(id) {
    return this.api.get(this.baseUrl + 'getSuppliercatgeryBySupplierId?addressId=' + this.getAddressId() + '&supplierId=' + id, {});
  }

  getCurrentbatchInformation(batchId) {
    return this.api.get(this.baseUrl + 'batchDetails/' + batchId, {});
  }


  private sortOrder: string = 'asc';
  private sortField: string = '';

  sort(array: any[], field: string): any[] {
    if (this.sortField === field) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortOrder = 'asc';
    }

    this.sortField = field;
    const comparison = this.sortOrder === 'asc' ? 1 : -1;

    return array.sort((a, b) => {
      if (typeof a[field] === 'string' && typeof b[field] === 'string') {
        return a[field].localeCompare(b[field]) * comparison;
      } else if (typeof a[field] === 'number' && typeof b[field] === 'number') {
        return (a[field] - b[field]) * comparison;
      } else {
        return 0;
      }
    });
  }

  currentOrdersList(supplierId) {
    return this.api.get(this.baseUrl + 'currentSupplierOrdersList/' + this.getAddressId() + '/' + supplierId, {});
  }

  previousOrdersList(supplierId) {
    return this.api.get(this.baseUrl + 'deliverSupplierOrdersList/' + this.getAddressId() + '/' + supplierId, {});
  }

  searchSupplier(body) {
    return this.api.post(this.baseUrl + 'searchSupplier', body, {},true)
  }

  searchSupplierorders(body) {
    return this.api.post(this.baseUrl + 'searchSupplierorders', body, {})
  }

  searchCategory(body) {
    return this.api.post(this.baseUrl + 'searchCategory', body, {})
  }

  searchItemtype(body) {
    return this.api.post(this.baseUrl + 'searchItemtype', body, {})
  }

  searchInventory(body) {
    return this.api.post(this.baseUrl + 'searchInventory', body, {},true)
  }

  orderBatchInformation(orderId) {
    return this.api.get(this.baseUrl + 'orderBatchInformation/' + orderId, {});
  }

  activeDeals(body) {
    return this.api.post(this.baseUrl + 'supplierActiveDeal', body, {});
  }

  supplierListOfOrder(catagoryId) {
    return this.api.get(this.baseUrl + 'supplierordersByCatagoryIdAndAddressId/' + this.getAddressId() + '/' + catagoryId, {});
  }

  supplierOrderInvoice(supplierordersId) {
    return this.api.get(this.baseUrl + 'supplierordersForInvoiceBySupplierordersId/' + this.getAddressId() + '/' + supplierordersId, {});
  }

  saveManufacturer(body) {
    body["addressId"]=this.getAddressId();
    return this.api.post(this.baseUrl + 'saveManufacturer', body, {});
  }

  manufacturerList(body) {
    body["addressId"]=this.getAddressId();
    return this.api.post(this.baseUrl + 'manufacturerList', body, {},true);
  }

  activateManufacturer(manufId) {
    return this.api.get(this.baseUrl + 'activateManufacturer?manufacturerId=' + manufId, {});
  }

  deactivateManufacturer(manufId) {
    return this.api.get(this.baseUrl + 'deactiveManufacturer?manufacturerId=' + manufId, {});
  }

  orderDispatchList(inventoryId) {
    return this.api.get(this.baseUrl + 'inventoryDispatchList?addressId=' + this.getAddressId() + '&inventoryId=' + inventoryId, {});
  }

  saveOrderDispatch(body) {
    return this.api.post(this.baseUrl + 'saveInventoryDispatch', body, {});
  }

  saveBulkOrder(body) {
    body["addressId"]= this.getAddressId();
    return this.api.post(this.baseUrl + 'createPurchaseOrder', body, {});
  }

  multipleOrders(orderId) {
    return this.api.get(this.baseUrl + 'multipleOrderItemList?addressId=' + this.getAddressId() + '&supplierordersId=' + orderId, {});
  }

  deliverBulkOrder(body) {
    return this.api.post(this.baseUrl + 'multipulItemOrderDeliver', body, {});
  }

  activateSubCatagory(hospitalAddressId, itemTypeId) {
    return this.api.post(this.baseUrl + 'activateItemtype?addressId=' + hospitalAddressId + '&itemTypeId=' + itemTypeId, {}, {})
  }

  deleteSubCatagory(hospitalAddressId, itemTypeId) {
    return this.api.delete(this.baseUrl + 'deleteItemtype?addressId=' + hospitalAddressId + '&itemTypeId=' + itemTypeId, {})
  }
  getInventoryDetails(body) {
    return this.api.post(this.baseUrl + 'inventoryDetails', body, {});
  }

  searchItemDetails(body){
    body['addressId'] = this.getAddressId();
    return this.api.post(this.baseUrl +'searchItemDetails', body, {});
  }

  getMultipleOrdersList(addressId, supplierOrderId) {
    return this.api.get(this.baseUrl + 'multipleOrderItemList?addressId=' + addressId + "&supplierordersId=" + supplierOrderId, {},true)
  }

  getInventoryInvoice(addressId, supplierOrderId) {
    return this.api.get(this.baseUrl + 'supplierordersForInvoiceBySupplierordersId/' + addressId + "/" + supplierOrderId, {},true)
  }
  saveInvoicePdf(body, addressId, supplierOrdersId) {
    return this.api.post(this.baseUrl + 'uploadInvoice?addressId=' + addressId + '&supplierordersId=' + supplierOrdersId, body, {})
  }
  getBatchInfo(addressId, supplierOrderId) {
    return this.api.get(this.baseUrl + 'supplierOrdersIdbatchInfomation?addressId=' + addressId + '&supplierordersId=' + supplierOrderId, {})
  }



  allroles(){
    return this.api.post(this.baseUrl+'allRole',{},{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  saveRoleMenu(body){
    return this.api.post(this.baseUrl + 'saveRolemenu', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }


  findRoleMenuByRoleName(roleName){
    return this.api.get(this.baseUrl+'findRoleMenuByRoleName?roleName='+roleName,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }


}



