import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { IvfService } from '../services/ivf.service';

@Component({
  selector: 'app-add-disposables',
  templateUrl: './add-disposables.component.html',
  styleUrls: ['./add-disposables.component.css']
})
export class AddDisposablesComponent implements OnInit, OnChanges {

  @Input() details: any;
  @Input() display: false;
  typeArray = ['string', 'number', 'date', 'datetime-local', 'boolean'];
  disposables = [];
  disposableArray = [];

  constructor(private globalStorage: GlobalStorageService, public activeModal: NgbActiveModal, private modalService: NgbModal, private ivfService: IvfService, private toaster: ToastrService) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.getAttributes();
  }

  ngOnInit(): void {
    this.getAttributes();
    this.getDispobles();
    if (!this.display) {
      this.disposableArray.push({
        "patientid": this.details?.patientId,
        "reportid": this.details?.reportId,
        "medicalTestId": this.details?.medicalTestId,
        "ivfAddressId": this.globalStorage.getItem('ivfAddressId'),
        "inventoryId": "",
        "name": "",
        "quantity": "",
        "unit": "",
        "usageUnitPrice": "",
        "totalPrice": "",
        "disposablesRegisteredBy": this.globalStorage.getItem('ivfUserId'),
        "reportName": this.details?.reportName,
        "isEditable": true
      })
    }
  }


  calAmount(i) {
    this.disposableArray[i].totalPrice = this.disposableArray[i].quantity * this.disposableArray[i].usageUnitPrice
  }

  getDispobles() {
    this.ivfService.disposableItemtype().subscribe(res => {
      this.disposables = res?.body?.data || [];
    }, error => {
      this.toaster.error(error?.error?.message);
    })
  }

  selDisposable(i) {
    const disp = this.disposables.filter(item => {
      return item.name == this.disposableArray[i].name
    })
    this.disposableArray[i].inventoryId = disp[0]?.inventoryId
    this.disposableArray[i].unitPrice = disp[0]?.unitPrice
    // this.disposableArray[i].unit=disp[0]?.unit
    this.disposableArray[i].usageUnitPrice = disp[0]?.usageUnitPrice

  }


  addAttr(index) {
    if (this.disposableArray[index-1].name && this.disposableArray[index-1].quantity) {
      if (this.disposableArray.length > 0) {
        this.disposableArray[index - 1].isEditable = false;
      }

      if (this.disposableArray.length == (index)) {
        this.disposableArray.push({
          "patientid": this.details?.patientId,
          "reportid": this.details?.reportId,
          "medicalTestId": this.details?.medicalTestId,
          "ivfAddressId": this.globalStorage.getItem('ivfAddressId'),
          "inventoryId": "",
          "name": "",
          "quantity": "",
          "unit": "",
          "usageUnitPrice": "",
          "totalPrice": "",
          "disposablesRegisteredBy": this.globalStorage.getItem('ivfUserId'),
          "reportName": this.details?.reportName,
          "isEditable": true
        })
      }
    } else {
      this.toaster.error('Please fill all the fields');
    }
  }

  editAttr(index: number) {
    this.disposableArray[index].isEditable = true;
  }

  saveAttr() {
    const bodyArr = this.disposableArray.filter(res => {
      if (res?.name && res?.name != "") {
        return res
      }
    })
    this.ivfService.saveDisposable({ "disposable": bodyArr }).subscribe(res => {
      this.toaster.success('Disposables Added Successfully');
      this.activeModal.close(true);
    }, error => {
      this.toaster.error(error?.error?.message);
    })
  }



  getAttributes() {
    const body = {
      // "reportid": this.details?.reportId,
      "medicalTestId": this.details?.medicalTestId,
      "ivfAddressId": this.globalStorage.getItem('ivfAddressId'),
      "patientid": this.details?.patientId
    }
    this.ivfService.disposableList(body).subscribe(res => {
      this.disposableArray = res?.data || [];
      if (!this.display) {
        this.disposableArray.push({
          "patientid": this.details?.patientId,
          "reportid": this.details?.reportId,
          "medicalTestId": this.details?.medicalTestId,
          "ivfAddressId": this.globalStorage.getItem('ivfAddressId'),
          "inventoryId": "",
          "name": "",
          "quantity": "",
          "unit": "",
          "usageUnitPrice": "",
          "totalPrice": "",
          "disposablesRegisteredBy": this.globalStorage.getItem('ivfUserId'),
          "reportName": this.details?.reportName,
          "isEditable": true
        })
      }
    })

  }

}
