import { GlobalStorageService } from 'src/app/global-storage.service';
import { Injectable } from '@angular/core';
import { Subject, observable, Observable } from 'rxjs'
import { HttpClient, HttpHandler, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ApicallsService } from '../apicalls.service';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DoctorService {

  constructor(private globalStorage: GlobalStorageService, private http: HttpClient, private ToastrService: ToastrService, private api: ApicallsService) {
    const hostname = window.location.hostname;

    if (hostname === 'localhost') {
      this.baseURL = environment.doctorBaseUrl1;
      this.patientBaseURL = environment.patientBaseUrl1;
      this.pharmaUrl = environment.pharmaUrl1;
      this.utilitiesUrl = environment.utilitiesUrl1;
      this.hosUrl = environment.hospitalUrl1;
      this.elastic = environment.elesticSearchUrl1;
      this.caretakerUrl=environment.caretakerUrl1;
      this.ivfUrl=environment.ivfUrl1;
      this.pdfUrl = environment.pdfUrl1;

    }
   }

  appointments
  dataFromHospital
  dataFromIvf
  private doctorDetailsSubject = new Subject<any>();
  Observable = this.doctorDetailsSubject.asObservable();

  private patientDetailsSubject = new Subject<any>();
  patientObservable = this.patientDetailsSubject.asObservable();

  private appointmentSubject = new Subject<any>();
  appointmentObservable = this.appointmentSubject.asObservable();

  private loginUserSubject = new Subject<any>();
  loginUsertObservable = this.loginUserSubject.asObservable();

  private myAssociansSubject = new Subject<any>();
  myAssociansObservable = this.myAssociansSubject.asObservable();

  private rescheduleAppointmentSubject = new Subject<any>();
  rescheduleAppointmentObservable = this.rescheduleAppointmentSubject.asObservable();

  private refreshSubject = new Subject<any>();
  refreshObservable = this.refreshSubject.asObservable();

  OpenSideNave(value: any) {
    this.doctorDetailsSubject.next(value);
  }
  saveWebData(value){
    this.dataFromHospital = value;
    this.dataStringSource.next(this.dataFromHospital);
}
saveIvfData(value){
this.dataFromIvf=value
this.dataStringSource.next(this.dataFromIvf);
}
  OpenPatientSideNave(value: any) {
    this.patientDetailsSubject.next(value);
  }

  OpenAppontment(value: any) {
    this.appointmentSubject.next(value);
  }

  getLoginUser(value: any) {
    this.loginUserSubject.next(value)
  }

  reloadCurrentAppointment() {
    this.rescheduleAppointmentSubject.next()
  }

  updateMyassocians() {
    this.myAssociansSubject.next()
  }

  refreshSideMenu() {
    this.refreshSubject.next()
  }
  sharingData
  dataStringSource = new Subject<any>();
  saveData(value) {
    this.sharingData = value;
    this.dataStringSource.next(this.sharingData);
  }

  baseURL = environment.doctorBaseUrl;
  patientBaseURL = environment.patientBaseUrl;
  pharmaUrl = environment.pharmaUrl;
  utilitiesUrl = environment.utilitiesUrl;
  hosUrl = environment.hospitalUrl;
  elastic = environment.elesticSearchUrl;
  caretakerUrl=environment.caretakerUrl;
  ivfUrl=environment.ivfUrl;
  pdfUrl = environment.pdfUrl;


  //headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) }

  doctorRegistration(doctor): Observable<any> {
    return this.api.post(this.baseURL + 'register', doctor, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) })
  }

  //-----------Doctor Profile------------

  getPatientAddress(request: any, userId: any): Observable<any> {
    let params = new HttpParams().set('forUserId', userId);
    return this.api.post(this.patientBaseURL + 'address/get',
      request, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' })
  }
  checkPrescriptionExistOrNot(appointmentId: any, userId: any): Observable<any> {
    let params = new HttpParams().set('forUserId', userId);
    return this.api.post(this.patientBaseURL + 'prescription/getprescriptionBydoctorappointmentId?doctorAppointmentId=' + appointmentId,
      {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' })
  }
  doctorInvoice(){
    return this.api.get(this.baseURL + 'invoice/fetchInvoiceByDoctorId?doctorid=' +this.globalStorage.getItem('doctorId'), { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })

  }
  getPatientProfilePic(request: any) {
    let params = new HttpParams().set('forUserId', request);
    return this.api.post(this.patientBaseURL + 'personaldetails/get/', {},
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': "DOCTOR", 'forUserId': request.forUserId.toString() }), observe: 'response' })
  }
  private sortOrder: string = 'asc';
  private sortField: string = '';

  sort(array: any[], field: string): any[] {
    if (this.sortField === field) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortOrder = 'asc';
    }

    this.sortField = field;
    const comparison = this.sortOrder === 'asc' ? 1 : -1;

    return array.sort((a, b) => {
      if (typeof a[field] === 'string' && typeof b[field] === 'string') {
        return a[field].localeCompare(b[field]) * comparison;
      } else if (typeof a[field] === 'number' && typeof b[field] === 'number') {
        return (a[field] - b[field]) * comparison;
      } else {
        return 0;
      }
    });
  }


  getAllPatientData(docId) {
    return this.api.get(this.baseURL + 'appointment/appointmentDetailsForDoctor?doctorId=' + docId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs') }), observe: 'response' })
  }
  // getLastPrescription(){
  //   return this.api.
  // }
  getInvoiceDetails(invoice) {
    return this.api.post(this.baseURL + 'invoice/criteria', invoice, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  getPrescription(appointmentId, userId) {
    let params = new HttpParams()
    params = params.set('forUserId', userId);
    return this.api.post(this.patientBaseURL + 'prescription/getprescriptionBydoctorappointmentId?doctorAppointmentId=' + appointmentId, {},
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(), "accept": "*/*" }) })
  }
  saveAssociation(association) {
    return this.api.post(this.baseURL + 'association', association, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })   //
  }
  saveNotes(body){
    return this.api.post(this.baseURL+'appointment/save/appointmentnotes',body,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getNotes(id){
    if(id!='null' || id !='undefined'){
      return this.api.get(this.baseURL+'appointment/appointmentnotes/appointmentid/'+id,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
    }
  }
  getCaretakerNotes(hospitalAddressId,inPatientId){
    return this.api.get(this.caretakerUrl+'appointment/appointmentnotesbyhospitalAddresId/'+hospitalAddressId+'/'+inPatientId,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getAssociationDetails(doctorId) {
    return this.api.get(this.baseURL + 'all/associations/' + doctorId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  deleteDoctorAssociation(associationId) {
    return this.api.delete(this.baseURL + 'association/' + associationId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  navigationDetails() {
    this.getPersonalDetails(this.globalStorage.getItem('doctorId')).subscribe(res => {
      var userdata = {
        'personalData': res['data'],
        'lastLogin': JSON.parse(this.globalStorage.getItem('lastLogin'))
      }
      this.getLoginUser(userdata)
    }, error => {
      this.ToastrService.error("Error in getting Personal details");
    })
  }


  getDoctorDetailsById(id) {
    return this.api.get(this.baseURL + 'id/' + id, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getDoctorDtsByEmail(email) {
    return this.api.get(this.baseURL + 'get/doctor/byemailid?emailId=' + email, {})
  }
  uploadProfilePic(formData, doctorId): Observable<any> {  //multipart/form-data
    // let params = new HttpParams().set('doctorId', doctor.doctorId);
    return this.api.post(this.baseURL + 'profilepic/' + doctorId, formData, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }

  updateDoctorProfile(request) {
    return this.api.put(this.baseURL + '/update', request, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  sendFeedback(formData) {
    return this.api.post(this.utilitiesUrl + 'feedback/create', formData, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  existingEmail(email): Observable<any> {
    return this.api.get(this.baseURL + 'verify/email/?emailId=' + email, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) })
  }
  userNameExistOrNot(userName) {
    return this.api.get(this.baseURL + 'doctorNameExists?doctorName=' + userName, {})
  }

  checkPhoneNumber(number){
    return this.api.get(this.baseURL + 'verifyphoneNumberExists?celnumber='+number, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) })
  }
  doctorUserName(request): Observable<any> {
    return this.api.get(this.baseURL + 'doctorNameExists?doctorName=' + request, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) })
  }

  deleteDoctorAddress(addressId) {
    return this.api.delete(this.baseURL + 'address/' + addressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }


  saveFee(feeDetails) {
    return this.api.post(this.baseURL + 'feeDetails', feeDetails, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  getDoctorFeeDetails(doctorId) {
    // let params = new HttpParams().set('doctorId', doctorId);
    return this.api.get(this.baseURL + 'getDoctorAllFeeDetails/' + doctorId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getDoctorFeeDetailsById(doctorId) {
    // let params = new HttpParams().set('doctorId', doctorId);
    return this.api.get(this.baseURL + 'feeDetails/' + doctorId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getPatientPrescriptions(endpoint, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.api.get(this.patientBaseURL + endpoint,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }) })
  }
  getPrescriptionPostSession(endpoint,body,userId){
    return this.api.post(this.patientBaseURL + endpoint, body,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }) })
  }
  deleteDoctorFee(feeId, active) {
    return this.api.delete(this.baseURL + 'feeDetails/' + feeId + '/' + active, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  deleteDoctorEducation(certificationId) {
    return this.api.delete(this.baseURL + 'certification/' + certificationId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }


  uploadDoctorProfile(doctor): Observable<any> {
    return this.api.post(this.baseURL + 'register', doctor, { headers: new HttpHeaders({ observe: 'response', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  uploadDoctorSignature(formData, doctorId): Observable<any> {  //multipart/form-data
    // let params = new HttpParams().set('doctorId', doctor.doctorId);
    return this.api.post(this.baseURL + 'doctorSignature/' + doctorId, formData, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }

  savePersonalDetails(doctorPersonaldetails): Observable<any> {
    return this.api.post(this.baseURL + 'personaldetail', doctorPersonaldetails, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })   //
  }
  // { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) }
  getPersonalDetails(doctorId) {
    if(doctorId!=-1 || doctorId!=undefined || doctorId!=null){
      return this.api.get(this.baseURL + 'personaldetail/doctorid/' + doctorId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
    }
  }


  getAddressDetails(doctorId) {
    return this.api.get(this.baseURL + 'address/doctorid/' + doctorId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  getCertificationDetails(doctorId) {
    return this.api.get(this.baseURL + 'certification/doctorid/' + doctorId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  saveCertificationDetails(certification): Observable<any> {
    return this.api.post(this.baseURL + 'certification', certification, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })   //
  }
  saveAddressDetails(address): Observable<any> {
    return this.api.post(this.baseURL + 'address/save', address, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })   //
  }

  //-----------Admin Doctors------------
  getAllDoctors(): Observable<any> {
    var newRegistraion = { "doctorFlags": ["NEWLY_REGISTERED"] }
    return this.api.post(this.baseURL + 'all/flags', newRegistraion, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getAllVerifiedDoctors(): Observable<any> {
    var verified = { "doctorFlags": ["VERIFIED"] }
    return this.api.post(this.baseURL + 'all/flags', verified, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getAllApprovedDoctors(): Observable<any> {
    var verified = { "doctorFlags": ["APPROVED"] }
    return this.api.post(this.baseURL + 'all/flags', verified, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  //-----------Admin Patients------------
  getAllPatients(): Observable<any> {
    var newRegistraion = { "patientFlags": ["NEWLY_REGISTERED"] }
    return this.api.post(this.patientBaseURL + 'all/flags', newRegistraion, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getAllVerifiedPatients(): Observable<any> {
    var verified = { "patientFlags": ["VERIFIED"] }
    return this.api.post(this.patientBaseURL + 'all/flags', verified, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getAllApprovedPatients(): Observable<any> {
    var verified = { "patientFlags": ["APPROVED"] }
    return this.api.post(this.patientBaseURL + 'all/flags', verified, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  //-----------BaseShedule------------

  validateBaseschedule(sheduleDate) {
    var obj = {
      "doctorId": this.globalStorage.getItem('doctorId'),
      "fromDate": "",
      "monthNumber": 0,
      "scheduleCalenderEnum": "CURRENT_MONTH",
      "scheduleDate": sheduleDate,
      "toDate": "",
      "weekOfYear": 0,
      "year": 0
    }
    return this.api.post(this.baseURL + 'schedule/all/bycriteria', obj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }


  getAppointmentByschedulehours(schedulehours) {
    return this.api.get(this.baseURL + 'appointment/all/schedulehours/' + schedulehours, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  getAppointmentDts(req) {
    return this.api.post(this.baseURL + 'appointment/search/', req, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  saveScheduleHours(reqObj) {
    return this.api.post(this.baseURL + 'schedule/save', reqObj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  deleteScheduleHours(schedulehoursId) {
    return this.api.delete(this.baseURL + 'schedulehours/id/' + schedulehoursId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  createBaseSchedule(scheduler): Observable<any> {
    return this.api.post(this.baseURL + 'baseschedule/save', scheduler, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  updateBaseSchedule(scheduler): Observable<any> {
    if(scheduler.basseScheduleId!='undefined' || scheduler.basseScheduleId!='null' || scheduler.basseScheduleId!=undefined || scheduler.basseScheduleId!=null){
      return this.api.post(this.baseURL + 'baseschedule/update/' + scheduler.basseScheduleId, scheduler, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
    }
  }
  
  currentDate = new Date();
  selectedItem = formatDate(this.currentDate, "yyyy-MM-dd", 'en-us')
  dateS = new Date(this.selectedItem)
  getDoctorBaseschedule(docid, month) {  //CURRENT_WEEK, CURRENT_DAY, CURRENT_MONTH
    var obj = {
      "doctorId": docid,
       "fromDate": this.selectedItem,
      // "maxResutls": 10,
      // "monthNumber": month,
      // "pageNum": 1,
      // "scheduleCalenderEnum": "CURRENT_MONTH",
       "toDate": formatDate((this.dateS.setMonth(this.dateS.getMonth() + 1)), "yyyy-MM-dd", 'en-us'),
      // "weekOfYear": 0,
      // "year": 0
    }
    return this.api.post(this.baseURL + 'schedule/all/bycriteria', obj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getDoctorSlots(ivfAddressId,id) {
    const fromDate = new Date().toISOString().split('T')[0];
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() + 14);
    const toDateStr = toDate.toISOString().split('T')[0];
    var obj = {
      "associateEntityId": ivfAddressId,
      "doctorId": id,
      "maxResutls": 100,
      "pageNum": 1,
      "fromDate": fromDate,
      "toDate": toDateStr
    }
    return this.api.post(this.baseURL + 'schedule/all/bycriteria', obj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  getDoctorBaseSheduleView(docid) {
    //return this.api.get(this.baseURL+'baseschedule/all/'+docid,{ headers: new HttpHeaders({'Content-Type': 'application/json', 'user-session':JSON.parse(this.globalStorage.getItem('userSession')),"accept": "*/*"})})
    return this.api.post(this.baseURL + 'baseschedule/get/' + docid + '/1/1000', {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  //-----------APPINMENTS------------


  rescheduleAppointment(appointment) {
    var reschedule = {
      "appointmentId": appointment.appointmentId,
      "appointmentStatus": "ENTITY_RESCHEDULED",
      "doctorId": parseInt(this.globalStorage.getItem('doctorId')),
      "reason": null,
      "scheduleHoursId": appointment.schedulehoursId
    }
    return this.api.post(this.baseURL + 'appointment/request/update/appointmentstatus', reschedule, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': appointment.userId.toString(), "accept": "*/*" }) })
  }

  acceptAppointment(appointment) {
    return this.api.post(this.baseURL + 'appointment/request/accept/' + appointment.appointmentId, appointment, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getAppointmentGraphValues(id, time) {
    return this.api.post(this.baseURL + 'executeReportforapoitment?doctorId=' + id + '&time=' + time, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getRevenueGraphValue(id, time) {
    return this.api.post(this.baseURL + 'executereportforrevenue?doctorId=' + id + '&time=' + time, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  cancelAppointment(appointment) {
    var cancel = {
      "appointmentId": appointment.appointmentId,
      "appointmentStatus": "ENTITY_CANCELLED",
      "doctorId": parseInt(this.globalStorage.getItem('doctorId')),
      "reason": 'test',
      "scheduleHoursId": appointment.schedulehoursId
    }
    return this.api.post(this.baseURL + 'appointment/request/update/appointmentstatus', cancel, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': appointment.userId.toString(), "accept": "*/*" }) })
  }

  appointmentsAdvanceSearch(appointment: any) {
    return this.api.post(this.baseURL + 'appointment/search', appointment, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }



  // getPatientAllPrecriptions(patientId){
  //          let params = new HttpParams();
  //          params=params.set('patientId',patientId)
  //          return this.api.get(this.baseURL+'prescription/view/all/',{params:params, headers: new HttpHeaders({'Content-Type': 'application/json', 'user-session':JSON.parse(this.globalStorage.getItem('userSession')),"accept": "*/*"})})
  // }


  getPatientAllAppointmentNotes(appointment) {
    let params = new HttpParams();
    params = params.set('doctorId', appointment.doctorId)
    params = params.set('patientId', appointment.patientId)
    return this.api.get(this.baseURL + 'appointment/previousAppointmentNotes/', { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  getPatientAppointmnetNotesByAppointmentId(appointment) {
    let params = new HttpParams();
    params = params.set('appointmentId', appointment.appointmentId)
    // params=params.set('patientId',appointment.patientId)
    return this.api.get(this.baseURL + 'appointment/appointmentnotes/', { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }




  // acceptPatientRequist(patient){
  //   return this.api.post(this.baseURL+'patient/request/accept/' +patient,{ headers: new HttpHeaders({'Content-Type': 'application/json', 'user-session':JSON.parse(this.globalStorage.getItem('userSession')),"accept": "*/*"})})
  // }
  // rejectPatientRequist(patient){
  //   return this.api.post(this.baseURL+'patient/request/reject/'+patient,{ headers: new HttpHeaders({'Content-Type': 'application/json', 'user-session':JSON.parse(this.globalStorage.getItem('userSession')),"accept": "*/*"})})
  // }

  // getPatientRequests(doctorId:number){
  //   return this.api.get(this.baseURL+'patient/all/newrequest/'+doctorId,{ headers: new HttpHeaders({'Content-Type': 'application/json', 'user-session':JSON.parse(this.globalStorage.getItem('userSession')),"accept": "*/*"})})
  // }


  //------------Prescription---------//

  savePresription(precription) {
    return this.api.post(this.patientBaseURL + 'prescription/fromdoctor/save', precription, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }




  //-----------Dashboard------------

  myAppoinments(docid) {  // "PREVIOUS", "CURRENT", "UPCOMING", "CANCELLED"
    var reqObj = {
      "doctorId": docid,
      "patientId": null,
      "viewAppointmentEnum": ["CURRENT", "UPCOMING",]
    }
    return this.api.post(this.baseURL + 'appointment/view/doctorself', reqObj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  myALLAppoinments(docid) {  // "PREVIOUS", "CURRENT", "UPCOMING", "CANCELLED"
    var reqObj = {
      "doctorId": docid,
      "patientId": null,
      "maxResult": 100,
      "pageNum": 1,
      "viewAppointmentEnum": ["CURRENT", "UPCOMING", "PREVIOUS", "CANCELLED"]
    }
    return this.api.post(this.baseURL + 'appointment/view/doctorself', reqObj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getAssignedPatients(hospitalAddressId){
    return this.api.get(this.hosUrl+'getInPatientByUsingDoctorId?doctorId='+hospitalAddressId,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getInvoiveData(reqObj) {
    return this.api.post(this.baseURL + 'invoice/criteria', reqObj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  getMyReports(doctorId) {
    //let params = new HttpParams();
    // params=params.set('doctorId',doctorId)
    return this.api.get(this.baseURL + 'myreport/' + doctorId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  
  getFollowUps(id,max,pgno){
    
    return this.api.get(this.baseURL + 'followupAppointmentList?doctorId='+id+'&maxResult='+max+'&pageNumber='+pgno , { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) },true)
  }

  getCurrentAppoinments(docid, p) {  // "PREVIOUS", "CURRENT", "UPCOMING", "CANCELLED"
    var reqObj = {
      "doctorId": docid,
      "patientId": null,
      "maxResult": 10,
      "pageNum": p,
      "viewAppointmentEnum": ["CURRENT"]
    }
    return this.api.post(this.baseURL + 'appointment/view/doctorself', reqObj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  getUpcomingAppoinments(docid, p) {
    var reqObj = {
      "doctorId": docid,
      "patientId": null,
      "maxResult": 12,
      "pageNum": p,
      "viewAppointmentEnum": ["UPCOMING"]
    }
    return this.api.post(this.baseURL + 'appointment/view/doctorself', reqObj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getPreviousAppointmentsOfPatient(body) {
    return this.api.post(this.baseURL + 'appointment/view/doctorself', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "moduleName": this.globalStorage.getItem('loggedInAs'), "accept": "*/*" }) })
  }
  getPreviousAppoinments(docid, p) {
    var reqObj = {
      "doctorId": docid,
      "maxResult": 12,
      "pageNum": p,
      "patientId": null,
      "viewAppointmentEnum": ["PREVIOUS"]
    }
    return this.api.post(this.baseURL + 'appointment/view/doctorself', reqObj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  updateNotifications(endpoint, notifId, request) {
    return this.api.post(this.baseURL + 'notificationalerts/updateNotificationalerts/' + endpoint + '?forUserId=' + notifId, request, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getCanceledAppoinments(docid, p) {
    var reqObj = {
      "doctorId": docid,
      "patientId": null,
      "maxResult": 12,
      "pageNum": p,
      "viewAppointmentEnum": ["CANCELLED"]
    }
    return this.api.post(this.baseURL + 'appointment/view/doctorself', reqObj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }


  getDoctorRating(doctorId) {
    return this.api.get(this.baseURL + 'rating/get/' + doctorId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getAppointmentById(appointmentId) {
    return this.api.get(this.baseURL + 'appointment/id/' + appointmentId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  doctorProfileCompletedOrNot(docId) {

    return this.api.post(this.baseURL + 'doctorProfileCompleted/' + docId, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  getDrugNames() {
    return this.api.get(this.pharmaUrl + 'drug/all', {})
  }

  getDrugsForPharmaPage(data) {
    return this.api.post(this.pharmaUrl + 'inventoryController/getallinventorybypharmacyaddresId', data, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  getLookip() {
    return this.api.get("https://192.168.1.158/api/utilities/lookupstatic/get/all/lookupTypes", { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) })
  }

  getCertificationList(module) {
    return this.api.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module='+module+'&type=CERTIFICATION', { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) });
  }

  getSpecialization() {
    return this.api.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=DOCTOR&type=SPECIALIZATION', {})
  }

  getIns() {
    return this.api.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=INSTRUCTIONS&type=INSTRUCTIONS', {})
  }

  getAllHospitals(hosName) {
    return this.api.get(this.elastic + 'hospitalcontroller/find/hospital/byName/' + hosName, {})
  }
  
  getAllMedicines(medicine) {
    return this.api.get(this.elastic + 'medicine/findmedicineByName/' + medicine, {})
  }
  getAllTest(test){
    return this.api.get(this.elastic + 'elasticsearchDiagnosticcenterController/searchDiagnosticcenterTestName/' + test, {})
  }
  getIvfMedicines(url){
    return this.api.get(this.ivfUrl+url,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) },true)
  }
  getIvfTest(url){
    return this.api.get(this.ivfUrl+url,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getCertificationDegrees(module, type)
  {
    return this.api.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=' + module + '&type=' + type,{});
  }


  getDoctorInvoiceDetails(doctorappointmentid, doctorid)
  {
    if(doctorappointmentid!='null' && doctorid!='null'){
      return this.api.get(this.baseURL + 'invoice/findInvoiceBydoctorappointmentid?doctorappointmentid=' + doctorappointmentid + '&doctorid=' + doctorid, {})
    }
  }

  downloadDoctorPdf(body): Observable<any>{
    return this.http.post(this.pdfUrl + 'doctor', body,{responseType: 'blob' as 'json'});
  }

  getInpatientRecord(inPatientId,hospitalAddressId){
    return this.api.get(this.hosUrl+'getInPatientDetails?hospitalAddresId='+hospitalAddressId+'&inpateintId='+inPatientId,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  searchTemplate(templateName,doctorId){
    return this.api.post(this.baseURL+'getPrescriptionByName?doctorId='+doctorId+'&name='+templateName,{},{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getTemplateDataById(id,doctorId){
    return this.api.get(this.baseURL+'getPrescription?doctorId='+doctorId+'&prescriptionId='+id,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  templateSearch(templateName,doctorId){
    return this.api.post(this.baseURL+'findPrescriptionByName?doctorId='+doctorId+'&name='+templateName,{},{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
}
