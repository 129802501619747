import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { DoctorPatientService } from 'src/app/AppServices/DoctorPatient.service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';
import { Router } from '@angular/router';
import { ObservableService } from 'src/app/shared/services/observable.service';
import { VitalsService } from 'src/app/shared/components/add-vitals/vitals.service';
import { PatientService } from 'src/app/shared/services/patient.service';
import { UserVitalsComponent } from 'src/app/common-components/user-vitals/user-vitals.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IvfService } from 'projects/ivf/src/app/services/ivf.service';
import { UserVitalsHistoryComponent } from 'src/app/common-components/user-vitals-history/user-vitals-history.component';

@Component({
  selector: 'app-vitals',
  templateUrl: './vitals.component.html',
  styleUrls: ['./vitals.component.css']
})
export class VitalsComponent implements OnInit, OnChanges {

  public scatterChartOptions: ChartOptions = {
    scales: {
      xAxes: [{
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MM/DD/YY'
          }
        },
        scaleLabel: {
        //  display: true,
          // labelString: 'Date'
        }
      }],
      yAxes: [{
        ticks: {
          min: 10 // Set the minimum value for the y-axis
        },
        scaleLabel: {
         // display: true,
          // labelString: 'Value'
        }
      }]
    }
  };

  public scatterChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Systolic',
      backgroundColor: 'rgba(255, 0, 0, 0.3)',
      borderColor: 'rgba(255, 0, 0, 0.3)',
      pointRadius: 10,
      showLine: false
    },
    {
      data: [],
      label: 'Diastolic',
      backgroundColor: 'rgba(0, 0, 255, 0.3)',
      borderColor: 'rgba(0, 0, 255, 0.3)',
      pointRadius: 10,
      fill: false,
      showLine: false
    }
  ];

  public scatterChartType: any = 'scatter';

  heartRateReports;
  @Input() patientVitals: any;
  @Input() class: any = 'modal-left';
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          max: 500,
          min: 0
        }
      }]
    }
  };

  bmi: any = 0;
  public barChartLabels = ['Jan', 'Feb', 'Mar'];
  public barChartType = 'bar';
  public barChartLegend = true;

  public barChartData = [

    { data: [65, 59, 80], label: 'Oxygen Level  ' },
    //{ data: [28, 48, 40], label: 'New Patient' }

  ];

  public heartrate: ChartDataSets[] = [
    { data: [], label: 'Heart Rate' },
  ];
  public temparature: ChartDataSets[] = [
    { data: [], label: 'Temperature' },
  ];
  public heartratelabels: Label[] = [];
  p
  public temperaturelabels: Label[] = [];



  public pressure: ChartDataSets[] = [
    { data: [], label: 'Systolic' },
    { data: [], label: 'Diastolic' },
  ];
  public weightm: ChartDataSets[] = [
    { data: [], label: 'Weight' },
  ];
  public pressurelabel: Label[] = [];

  public weightlabel: Label[] = [];

  public weightlabelcol: Color[] = [
    {
      borderColor: '#533db4',
      backgroundColor: '#f2f3f7',
    },
  ];

  public pressurecol: Color[] = [
    {
      borderColor: '#358d51',
      backgroundColor: '#e2f6e9',
    },
    {
      borderColor: '#533db4',
      backgroundColor: '#f2f3f7',
    },
  ];


  public temparaturecol: Color[] = [
    {
      borderColor: '#ff8935',
      backgroundColor: '#ffe7c1',
    },
  ];


  public heartratecol: Color[] = [
    {
      borderColor: '#ff2e00',
      backgroundColor: '#fff0e9',
    },
  ];

  public lineChartOptions: (ChartOptions & { annotation?: any }) = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          max: 200,
          min: 10
        }
      }]
    }
  };
  public lineChartColors: Color[] = [
    {
      borderColor: '#358d51',
      backgroundColor: '#e2f6e9',
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  isHospital = false
  public pieChartLabels: string[] = ['Appointments', 'Referrals', 'Associate', 'Diagnostic'];
  public pieChartData: number[] = [40, 30, 20, 10];
  public pieChartType: string = 'pie';
  selectedPeriod = 'CURRENT_DATE'; selectedTempPeriod = 'CURRENT_DATE'; selectedPressurePeriod = 'CURRENT_DATE'; selectedWeightPeriod = 'CURRENT_DATE'
  constructor(private globalStorage: GlobalStorageService, private doctorPatient: DoctorPatientService, private reports: PatientScheduleControlService, private observableService: ObservableService,
    private toastrService: ToastrService, private sanitizer: DomSanitizer, private profileImage: PatientService,
    private router: Router, private vitalsService: VitalsService, private ivfService: IvfService,
    private modalService: NgbModal) {
    if (this.reports.vitalsData != undefined || this.reports.vitalsData != null) {
      this.patientVitals = this.reports.vitalsData
    }
    this.observableService.invokeEvent_vitals.subscribe(value => {
      // console.log('after update', this.patientVitals)
      if (value == 'someValue') {
        console.log('after update', this.patientVitals)
        this.getPatientVitals(this.patientVitals)
      }
    })
  }

  ngOnInit(): void {
    if (this.patientVitals != undefined) {
      this.getPatientVitals(this.patientVitals);
      if (this.patientVitals?.type == 'Hospital') {
        this.isHospital = true
      }
    }
    else {
    }
    this.ChangePeriod('WEEK')
    // this.ChangeTemperaturePeriod('WEEK')
    // this.ChangeBloodPressureuePeriod('WEEK')
    // this.ChangeWeightPeriod('WEEK')
  }
  vitalsArray: any
  vitals
  pulse
  weight
  heartRate
  temp; height; oxygen; fasting; random; existedForUserId; systolic; diastolic
  getPatientVitals(patient) {
    this.existedForUserId = this.globalStorage.getItem('forUserId');

    this.globalStorage.setItem("forUserId", patient?.userId);
    if (patient.userId) {

    }
    else {
      patient.userId = patient?.AppointmentDetails?.userId
    }
    if (this.router.url == '/diagnosticDashboard' || this.router.url == '/diagnosticAppointments' || this.router.url == '/cureMetric/diagnosticDashboard') {
      var vitals = {
        "forUserId": patient.userId,
        "userId": patient.userId
        //"vitalsByDate": patient.AppointmentDetails.appointmentDate

      }
    }
    if (this.router.url == '/clinic-dashboard' || this.router.url == '/in-patient-details' || this.router.url == '/in-patient-list') {
      var vitals = {
        "forUserId": patient.userId,
        "userId": patient.userId,
        //"vitalsByDate": patient.AppointmentDetails.appointmentDate

      }
    }
    if (this.router.url == '/caretaker-patients' || this.router.url == '/cureMetric/caretaker-patients') {
      var vitals = {
        "forUserId": patient.userId,
        "userId": JSON.parse(this.globalStorage.getItem('forUserId'))
        //"vitalsByDate": patient.AppointmentDetails.appointmentDate

      }
    }
    if (this.router.url == '/caretaker-dashboard' || this.router.url == '/caretaker-appointments' || this.router.url == '/caretaker-assignedUsers') {
      // $('.ve').removeClass('curser-null')
      var vitals = {
        "forUserId": patient.userId,
        "userId": patient.userId
        //"vitalsByDate": patient.AppointmentDetails.appointmentDate

      }
    }
    if (this.router.url == '/clinic-users' || this.router.url == '/caretaker-dashboard' || this.router.url == '/cureMetric/clinic-users' || this.router.url == 'ivf-patients' ||
      this.router.url == '/ivf-dashboard' || this.globalStorage.getItem('loggedInAs') == 'IVF') {
      var vitals = {
        "forUserId": patient.userId,
        "userId": patient.userId
        //"vitalsByDate": patient.AppointmentDetails.appointmentDate

      }
    }
    if (this.router.url == '/dashboard' || this.router.url == '/Appoinments' || this.router.url == '/PatientsList') {
      var vitals = {
        "forUserId": patient.userId,
        "userId": JSON.parse(this.globalStorage.getItem('doctorId'))
        //"vitalsByDate": patient.AppointmentDetails.appointmentDate

      }
    }
    ///clinic-appointments //opd-patient-list
    if (this.router.url.includes('clinic-appointments') || this.router.url.includes('opd-patient-list')) {
      var vitals = {
        "forUserId": patient.userId,
        "userId": patient.userId
        //"vitalsByDate": patient.AppointmentDetails.appointmentDate

      }
    }
    //var localForUserId = this.globalStorage.getItem('forUserId');
    // this.globalStorage.setItem("loggedInAs", "PATIENT")
    if (this.globalStorage.getItem('loggedInAs') == 'IVF') {
      console.log(patient)
      this.ivfService.getIvfPatientVitals(this.globalStorage.getItem('ivfAddressId'), patient.userId).subscribe(res => {

        if (this.patientVitals?.bloodgroup) {

        }
        else {
          this.patientVitals['bloodgroup'] = this.patientVitals?.AppointmentDetails?.bloodgroup
        }
        this.vitalsArray = res['body']['data']?.reverse()
        this.vitals = null;
        this.pulse = this.vitalsArray?.filter((obj) => obj?.vitalname == "bloodPressure")
        this.pulse = this.pulse?.[0]
        this.systolic = this.vitalsArray?.filter((obj) => obj?.vitalname == "systolic")
        this.systolic = parseInt(this.systolic?.[0]?.['vitalValue'])
        console.log('sys value', this.systolic)
        this.diastolic = this.vitalsArray?.filter((obj) => obj?.vitalname == "diastolic")
        this.diastolic = parseInt(this.diastolic?.[0]?.['vitalValue'])
        this.weight = this.vitalsArray?.filter((obj) => obj?.vitalname == "weight")
        this.weight = this.weight?.[0]
        this.heartRate = this.vitalsArray?.filter((obj) => obj?.vitalname == "pulse")
        this.heartRate = this.heartRate?.[0]
        this.temp = this.vitalsArray?.filter(obj => obj?.vitalname == "temperature")
        this.temp = this.temp?.[0]
        this.oxygen = this.vitalsArray?.filter(obj => obj?.vitalname == "oxygenLevel")
        this.oxygen = this.oxygen?.[0]
        this.height = this.vitalsArray?.filter(obj => obj?.vitalname == "height")
        this.height = this.height?.[0]
        this.fasting = this.vitalsArray?.filter(obj => obj?.vitalname == "fasting")
        this.fasting = this.fasting?.[0]
        this.random = this.vitalsArray?.filter(obj => obj?.vitalname == "random")
        this.random = this.random?.[0]
        this.bmi = this.vitalsArray?.filter(obj => obj?.vitalname == "bmi")
        this.bmi = this.bmi?.[0]
         this.updateBMI();
      })
      this.ChangePeriod('WEEK');
    }
    else {
      var vitals = {
        "forUserId": patient.userId,
        "userId": patient.userId
      }
      this.doctorPatient.getPatientVitalDetails(vitals).subscribe(res => {
        console.log('vitals get', res)
        //this.globalStorage.setItem("forUserId", localForUserId)
        // this.globalStorage.setItem("loggedInAs", "DOCTOR")
        //this.vitalsArray.length=0;
        this.vitalsArray = res['data']?.reverse()
        this.vitals = null;
        this.pulse = this.vitalsArray?.filter((obj) => obj?.vitalname == "bloodPressure")
        this.pulse = this.pulse?.[0]
        this.systolic = this.vitalsArray?.filter((obj) => obj?.vitalname == "systolic")
        this.systolic = parseInt(this.systolic?.[0]?.['vitalValue'])
        console.log('sys value', this.systolic)
        this.diastolic = this.vitalsArray?.filter((obj) => obj?.vitalname == "diastolic")
        this.diastolic = parseInt(this.diastolic?.[0]?.['vitalValue'])
        this.weight = this.vitalsArray?.filter((obj) => obj?.vitalname == "weight")
        this.weight = this.weight?.[0]
        this.heartRate = this.vitalsArray?.filter((obj) => obj?.vitalname == "pulse")
        this.heartRate = this.heartRate?.[0]
        this.temp = this.vitalsArray?.filter(obj => obj?.vitalname == "temperature")
        this.temp = this.temp?.[0]
        this.oxygen = this.vitalsArray?.filter(obj => obj?.vitalname == "oxygenLevel")
        this.oxygen = this.oxygen?.[0]
        this.height = this.vitalsArray?.filter(obj => obj?.vitalname == "height")
        this.height = this.height?.[0]
        this.fasting = this.vitalsArray?.filter(obj => obj?.vitalname == "fasting")
        this.fasting = this.fasting?.[0]
        this.random = this.vitalsArray?.filter(obj => obj?.vitalname == "random")
        this.random = this.random?.[0]
        this.bmi = this.vitalsArray?.filter(obj => obj?.vitalname == "bmi")
        this.bmi = this.bmi?.[0]
        //this.vitals=JSON.parse(this.vitalsArray[this.vitalsArray.length-1].vitalValue)
        // this.updateBMI();
      }, error => {
        // this.globalStorage.setItem("forUserId", localForUserId)
        // this.globalStorage.setItem("loggedInAs", "DOCTOR")
        this.toastrService.error("Error in getting patient Vitals")
      })
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    console.log('after update', this.patientVitals)
    if ('patientVitals' in changes) {
      console.log('changed')
      if (this.patientVitals != undefined) {
        this.getPatientVitals(this.patientVitals);
      }
    }
  }

  updateBMI() {
    if (this.height && this.weight) {
      const height = parseFloat(this.height.vitalValue);
      const weightInLb = parseFloat(this.weight.vitalValue);

      if (height && weightInLb) {
        const heightInMeters = height * 0.3048;
        const weightInKg = weightInLb; // Convert lb to kg
        // const weightInKg = weightInLb * 0.453592; // Convert lb to kg
        const bmi_temp = weightInKg / (heightInMeters * heightInMeters);
        this.bmi = bmi_temp.toFixed(2);
      }
    }
  }

  allReports;sys;dia
  ChangePeriod(selected) {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    var request = {
      "forUserId": this.patientVitals?.userId,
      "userId": this.patientVitals?.userId,
      "time": selected,
      "vitalEnum": [
        "oxygenLevel", "fasting", "random", "pulse", "temperature", "bloodPressure", "systolic", "diastolic", "height", "weight"
      ]
    }
    if (this.router.url == '/ivf-dashboard' || this.router.url == '/appointments' || this.router.url == '/Appoinments' || this.router.url == '/dashboard' || this.router.url == '/in-patient-details' ||
      this.router.url=='/clinic-dashboard' || this.router.url =='/caretaker-dashboard' || this.router.url =='/caretaker-assignedUsers' ||
      this.router.url == '/ivf-patients' || this.router.url == '/clinic-appointments' || this.router.url == '/clinic-users' || this.router.url == '/diagnosticDashboard' || this.router.url == '/diagnosticAppointments') {
      console.log('request from vital components', request)
      if (this.patientVitals?.userId) {
       // this.doctorPatient.getHeartRateReports(selected, request, this.patientVitals?.userId).subscribe(res => {
          this.doctorPatient.getUserVitalsForGraph(request, this.patientVitals?.userId).subscribe(res => {
          // this.heartRateReports = JSON.parse(res['body']['data']['data'])
          this.allReports = res['body']['data']
          //this.heartRateReports = JSON.parse(res['body']['data']['data'])
          var heartRate
          // heartRate = this.allReports.filter(obj => obj.vitalValue == 'pulse')
          // var temp = this.allReports.filter(obj => obj.vitalValue == 'temperature')
          // var weight = this.allReports.filter(obj => obj.vitalValue == 'weight')
          // var bp = this.allReports.filter(obj => obj.vitalValue == "bloodPressure")
          // this.heartRateReports = JSON.parse(heartRate[0]['data'])
          // this.temparatureReports = JSON.parse(temp[0]['data'])
          // this.weightReports = JSON.parse(weight[0]['data'])
          // this.bloodPressureReports = JSON.parse(bp[0]['data'])
          heartRate = this.allReports?.['pulse']
          var temp = this.allReports?.['temperature']
          var weight = this.allReports?.['weight']
          var bp = this.allReports?.['bloodPressure']
          this.heartRateReports = JSON.parse?.(heartRate?.['data'])
          this.temparatureReports = JSON.parse?.(temp?.['data'])
          this.weightReports = JSON.parse?.(weight?.['data'])
          this.bloodPressureReports = JSON.parse?.(bp?.['data'])
          this.sys=JSON.parse?.(this.allReports?.['systolic']?.['data'])
          this.dia=JSON.parse?.(this.allReports?.['diastolic']?.['data'])
          this.heartratelabels=[];
          this.temperaturelabels=[];
          this.weightlabel=[];
          this.pressurelabel=[];

          for (var i = 0; i < this.heartRateReports?.rows?.length; i++) {
            this.heartrate[0]['data'][i] = this.heartRateReports?.rows[i]['c']['1']['v']
            this.heartratelabels.push(this.heartRateReports?.rows[i]['c']['0']['v'])
          }
          for (var i = 0; i < this.temparatureReports?.rows?.length; i++) {
            this.temparature[0]['data'][i] = this.temparatureReports?.rows[i]['c']['1']['v']
            this.temperaturelabels.push(this.temparatureReports?.rows[i]['c']['0']['v'])
          }
          for (var i = 0; i < this.weightReports?.rows?.length; i++) {
            this.weightm[0]['data'][i] = this.weightReports?.rows[i]['c']['1']['v']
            this.weightlabel.push(this.weightReports?.rows[i]['c']['0']['v'])
          }
          for (var i = 0; i < this.bloodPressureReports?.rows?.length; i++) {
            this.pressure[0]['data'][i] =this.sys?.rows?.[i]?.['c']?.['1']?.['v']
          this.pressure[1]['data'][i] = this.dia?.rows?.[i]?.['c']?.['1']?.['v']
            this.pressurelabel.push(this.bloodPressureReports?.rows[i]['c']['0']['v'])
            this.scatterChartData[0]['data'][i]=({"x":new Date(this.bloodPressureReports?.rows[i]?.['c']?.['0']?.['v']),"y":this.sys?.rows?.[i]?.['c']?.['1']?.['v']})
            this.scatterChartData[1]['data'][i]=({"x":new Date(this.bloodPressureReports?.rows[i]?.['c']?.['0']?.['v']),"y":this.dia?.rows?.[i]?.['c']?.['1']?.['v']})
          }
        })
      }
    }
  }
  temparatureReports
  ChangeTemperaturePeriod(selected) {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    var request = {
      "forUserId": this.patientVitals.userId,
      "userId": this.patientVitals.userId
    }
    this.doctorPatient.getTemperatureReports(selected, request, this.patientVitals.userId).subscribe(res => {
      this.temparatureReports = JSON.parse(res['body']['data']['data'])
      for (var i = 0; i < this.temparatureReports.rows.length; i++) {
        this.temparature[0]['data'][i] = this.temparatureReports.rows[i]['c']['1']['v']
        this.temperaturelabels.push(this.temparatureReports.rows[i]['c']['0']['v'])
      }
      // this.temparature[0].data[0]=this.temparatureReports.rows[0]['c']['1']['v']
      // this.temperaturelabels[0]=this.temparatureReports.rows[1]['c']['1']['v']
    })
  }
  bloodPressureReports
  ChangeBloodPressureuePeriod(selected) {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    var request = {
      "forUserId": this.patientVitals.userId,
      "userId": this.patientVitals.userId
    }
    this.doctorPatient.getPressureReports(selected, request, this.patientVitals.userId).subscribe(res => {
      this.bloodPressureReports = JSON.parse(res['body']['data']['data'])
      for (var i = 0; i < this.bloodPressureReports.rows.length; i++) {
        this.pressure[0]['data'][i] = this.bloodPressureReports.rows[i]['c']['1']['v']
        this.pressurelabel.push(this.bloodPressureReports.rows[i]['c']['0']['v'])
      }
      // this.pressure[0].data[0]=this.bloodPressureReports.rows[0]['c']['1']['v']
      // this.pressurelabel[0]=this.bloodPressureReports.rows[1]['c']['1']['v']
    })
  }
  weightReports
  ChangeWeightPeriod(selected) {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    var request = {
      "forUserId": this.patientVitals.userId,
      "userId": this.patientVitals.userId
    }
    this.doctorPatient.getWeightReports(selected, request, this.patientVitals.userId).subscribe(res => {
      this.weightReports = JSON.parse(res['body']['data']['data'])
      for (var i = 0; i < this.weightReports.rows.length; i++) {
        this.weightm[0]['data'][i] = this.weightReports.rows[i]['c']['1']['v']
        this.weightlabel.push(this.weightReports.rows[i]['c']['0']['v'])
      }
      // this.weightm[0].data[0]=this.weightReports.rows[0]['c']['1']['v']
      // this.weightlabel[0]=this.weightReports.rows[1]['c']['1']['v']
    })
  }

  checkCareTaker() {
    return (this.globalStorage.getItem('loggedInAs') == 'CARETAKER' || this.globalStorage.getItem('loggedInAs') == 'IVF' || this.globalStorage.getItem('loggedInAs') == 'HOSPITAL');
  }

  showUserVitals() {
    let wclass = 'modal-left hw-30'
    if (this.globalStorage.getItem('loggedInAs') == 'IVF') {
      wclass = 'right w-40'
    }
    if(this.router.url=='/dashboard' || this.router.url=='/Appoinments'){
      var userVitals = this.modalService.open(UserVitalsComponent, {
        centered: false,
        backdrop: 'static',
        keyboard: false,
        windowClass: 'modal-right hw-40 customz-index'
      })
      userVitals.componentInstance.user_id = this.globalStorage.getItem('forUserId');
      userVitals.componentInstance.userPersonalData = this.patientVitals
      userVitals.result.then(
        (reason) => {
          this.getPatientVitals(this.patientVitals);
          this.ChangePeriod('WEEK')
        },
        (result) => {
          this.getPatientVitals(this.patientVitals);
          this.ChangePeriod('WEEK')
        }
      )
    }
    else{
      const userVitals = this.modalService.open(UserVitalsComponent, {
        centered: false,
        backdrop: 'static',
        keyboard: false,
        windowClass: wclass,
      });
      userVitals.componentInstance.user_id = this.globalStorage.getItem('forUserId');
      userVitals.componentInstance.userPersonalData = this.patientVitals
      userVitals.result.then(
        (reason) => {
          this.getPatientVitals(this.patientVitals);
          this.ChangePeriod('WEEK')
        },
        (result) => {
          this.getPatientVitals(this.patientVitals);
          this.ChangePeriod('WEEK')
        }
      )
    }
  }


  viewUserVitalsHistory(vital,vitalValue) {
    let wclass = 'right w-40'
    switch (this.globalStorage.getItem('loggedInAs')) {
      case 'CARETAKER':
        wclass = 'modal-left hw-40'
        break;
      case 'IVF':
        wclass = 'right w-40'
        break;
      case 'HOSPITAL':
        wclass = 'modal-left hw-40'
    }

    if (!this.checkCareTaker()) {
      this.showUserVitals();
    } 
    else {
      const userVitals = this.modalService.open(UserVitalsHistoryComponent, {
        centered: false,
        backdrop: 'static',
        keyboard: false,
        windowClass: wclass,
      });
      userVitals.componentInstance.vitalObj = vital;
      userVitals.componentInstance.userId= this.patientVitals.userId
      userVitals.componentInstance.vitalName=vitalValue
      userVitals.result.then(
        (result) => {

        },
        (reason) => {
        }
      )
    }

  }

  editVitals() {
    if (this.globalStorage.getItem('loggedInAs') == 'IVF' || this.router.url == '/caretaker-dashboard' || this.router.url == '/caretaker-appointments' || this.router.url == '/caretaker-assignedUsers') {
      // console.log('save data', this.patientVitals)
      this.vitalsService.saveData(this.patientVitals?.userId)
      this.vitalsService.saveInPatient(this.patientVitals)
      this.observableService.vitalsedit(this.patientVitals)
    }
  }

}
