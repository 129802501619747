import { GlobalStorageService } from 'src/app/global-storage.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RolesControllerService {

  roles = new BehaviorSubject<any>([]);
  roles$ = this.roles.asObservable();


  rolesList=new BehaviorSubject<any>({
    RegistrationCount: false,
    FertilityOP: false,
    Packages: false,
    IVFTheatres: false,
    FertilityLab: false,
    GamateStock: false,
    DCAppointments: false,
    RevenueReports: false,
    Doctor: false,
    Nurses: false,
    Staff: false,
    Protocols: false,
    Current: false,
    Upcoming: false,
    Previous: false,
    Cancelled: false,
    UnpaidPrescriptions: false,
    PaidPrescription: false,
    CurrentStimulations: false,
    AllStimulations: false,
    PatientsManage: false,
    EditMedicalHistory: false,
    BookAppointment: false,
    AddPrescriptions: false,
    AddNotes: false,
    StartCycle: false,
    StartStimulation: false,
    StartProcedure: false,
    MedicalHistory: false,
    Prescription: false,
    PreviousAppointment: false,
    CaseDetails: false,
    AddUpdateVitals: false,
    MaleAddPrescriptions: false,
    MaleAddNotes: false,
    MaleMedicalHistory: false,
    MalePrescription: false,
    MalePreviousAppointment: false,
    MaleCaseDetails: false,
    MaleAddUpdateVitals: false,
  });
  rolesList$=this.rolesList.asObservable()

  constructor(private globalStorage: GlobalStorageService) {


  }

private permissions = new BehaviorSubject<any>({});
permissions$ = this.permissions.asObservable();

private hospitalPermissionsMapping = {
  registrationCountPermission: "Registration Count",
  fertilityOPPermission: "Fertility OP",
  packagesPermission: "Packages",
  ivfTheatresPermission: "IVF Theatres",
  fertilityLabPermission: "Fertility Lab",
  gamateStockPermission: "Gamate Stock",
  andrologyPermission: "Andrology",
  AppointmentsPermission: "Appointments",
  stimulationsPermission: "Stimulations",
  unpaidPrescriptionPermission: "Unpaid Prescription",
  dcAppointmentsPermission: "DC Appointments",
  patientsPermission: "Patients",
  revenueReportsPermission: "Revenue Reports",
  doctorPermission: "Doctor",
  nursesPermission: "Nurses",
  staffPermission: "Staff",
  protocolsPermission: "Protocols",
  currentPermission: "Current",
  upcomingPermission: "Upcoming",
  previousPermission: "Previous",
  cancelledPermission: "Cancelled",
  unpaidPrescriptionsPermission: "Unpaid Prescriptions",
  paidPrescriptionPermission: "Paid Prescription",
  currentStimulationsPermission: "Current Stimulations",
  allStimulationsPermission: "All Stimulations",
  patientsManagePermission: "Patients Manage",
  editMedicalHistoryPermission: "Edit Medical History",
  bookAppointmentPermission: "Book Appointment",
  addPrescriptionsPermission: "Add Prescriptions",
  addNotesPermission: "Add Notes",
  startCyclePermission: "Start Cycle",
  startStimulationPermission: "Start Stimulation",
  startProcedurePermission: "Start Procedure",
  medicalHistoryPermission: "Medical History",
  prescriptionPermission: "Prescription",
  previousAppointmentPermission: "Previous Appointment",
  caseDetailsPermission: "Case Details",
  addUpdateVitalsPermission: "Add/Update Vitals",
  maleAddPrescriptionsPermission: "Male Add Prescriptions",
  maleAddNotesPermission: "Male Add Notes",
  maleMedicalHistoryPermission: "Male Medical History",
  malePrescriptionPermission: "Male Prescription",
  malePreviousAppointmentPermission: "Male Previous Appointment",
  maleCaseDetailsPermission: "Male Case Details",
  maleAddUpdateVitalsPermission: "Male Add/Update Vitals",
  roomsPermission:"Rooms"

  // Add other mappings as needed
};

private ivfPermissionsMapping = {
    registrationCountPermission: "Registration Count",
  fertilityOPPermission: "Fertility OP",
  packagesPermission: "Packages",
  ivfTheatresPermission: "IVF Theatres",
  fertilityLabPermission: "Fertility Lab",
  gamateStockPermission: "Gamate Stock",
  andrologyPermission: "Andrology",
  AppointmentsPermission: "Appointments",
  stimulationsPermission: "Stimulations",
  unpaidPrescriptionPermission: "Unpaid Prescription",
  dcAppointmentsPermission: "DC Appointments",
  patientsPermission: "Patients",
  revenueReportsPermission: "Revenue Reports",
  doctorPermission: "Doctor",
  nursesPermission: "Nurses",
  staffPermission: "Staff",
  protocolsPermission: "Protocols",
  currentPermission: "Current",
  upcomingPermission: "Upcoming",
  previousPermission: "Previous",
  cancelledPermission: "Cancelled",
  unpaidPrescriptionsPermission: "Unpaid Prescriptions",
  paidPrescriptionPermission: "Paid Prescription",
  currentStimulationsPermission: "Current Stimulations",
  allStimulationsPermission: "All Stimulations",
  patientsManagePermission: "Patients Manage",
  editMedicalHistoryPermission: "Edit Medical History",
  bookAppointmentPermission: "Book Appointment",
  addPrescriptionsPermission: "Add Prescriptions",
  addNotesPermission: "Add Notes",
  startCyclePermission: "Start Cycle",
  startStimulationPermission: "Start Stimulation",
  startProcedurePermission: "Start Procedure",
  medicalHistoryPermission: "Medical History",
  prescriptionPermission: "Prescription",
  previousAppointmentPermission: "Previous Appointment",
  caseDetailsPermission: "Case Details",
  addUpdateVitalsPermission: "Add/Update Vitals",
  worksheetPermission: "Worksheet",
  maleAddPrescriptionsPermission: "Male Add Prescriptions",
  maleAddNotesPermission: "Male Add Notes",
  maleMedicalHistoryPermission: "Male Medical History",
  malePrescriptionPermission: "Male Prescription",
  malePreviousAppointmentPermission: "Male Previous Appointment",
  maleCaseDetailsPermission: "Male Case Details",
  maleAddUpdateVitalsPermission: "Male Add/Update Vitals",
};



setRoles(roles): void {
  let permission={}

  if(this.globalStorage.getItem('loggedInAs')=='IVF'){
    permission=this.ivfPermissionsMapping;
  }else{
    permission=this.hospitalPermissionsMapping;
  }


  this.roles.next(roles)
  const rolesArray = this.roles.getValue().map(item => item.menuName);
  // console.log(rolesArray)


  const newPermissions = {};

  for (const [permissionKey, role] of Object.entries(permission)) {
    newPermissions[permissionKey] = rolesArray.includes(role);
  }

  this.permissions.next({
    ...this.permissions.value,
    ...newPermissions
  });
  // console.log('permissions')
  // console.log(this.permissions.value)
}

}
